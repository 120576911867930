import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
}

export function Tooltip({
  children,
  content,
  placement = "bottom",
}: TooltipProps): JSX.Element {
  const [open, setOpen] = useState(false);
  return (
    <Popover className="flex relative" style={{ zIndex: 20 }}>
      <Popover.Button
        onMouseEnter={() => {
          setOpen(true);
        }}
        onMouseLeave={() => {
          setOpen(false);
        }}
      >
        {children}
      </Popover.Button>
      <Transition
        show={open}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          static
          className={clsx(
            "absolute transform",
            {
              bottom: "top-full left-1/2 mt-3 -translate-x-1/2",
              top: "bottom-full left-1/2 mt-3 -translate-x-1/2",
              left: "right-full mr-3",
              right: "left-full ml-3",
            }[placement]
          )}
        >
          <div className="w-40 overflow-hidden rounded-md bg-white dark:bg-stone-900 p-2 text-xs text-black dark:text-gray-400 shadow-lg ring-1 ring-black ring-opacity-5">
            {content}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
