import {
  BOX_STATIC_CLASSNAME,
  BoxComponent,
  BUTTON_STATIC_CLASSNAME,
  ButtonComponent,
  Component,
  ComponentType,
  DOCUMENT_STATIC_CLASSNAME,
  DocumentComponent,
  ImageComponent,
  NAVBAR_ICON_STATIC_CLASSNAME,
  NAVBAR_STATIC_CLASSNAME,
  NavbarComponent,
  PAGE_STATIC_CLASSNAME,
  PageComponent,
  TEXT_STATIC_CLASSNAME,
  TextComponent,
} from "shared/lib/component.types";
import {
  ColorMediaShape,
  CustomMediaShape,
  GradientMediaShape,
  MediaShape,
  MediaType,
  VideoMediaShape,
} from "shared/lib/media.types";
import clsx from "clsx";
import {
  Border,
  LinkIcon,
  LinkToEnum,
  MobileResponsiveness,
  NavbarItem,
  NavbarItemType,
  NavbarItemWithNew,
  Shadow,
  Wrap,
} from "shared/lib/util.types";
import CheatSheet from "./tailwindtocss/cheatsheet";

export const BREAKPOINTS = CheatSheet[0].content[3].table.map(
  (bkpt) => bkpt[0]
);

export function resolveBorderClassNames(border: Border): string[] {
  const parsedClassNames = new Set<string>();
  if (border?.width && border?.style) {
    parsedClassNames.add(
      clsx({
        border: border.width === 1,
        [`border-${String(border.width)}`]: border.width !== 1,
      })
    );

    const borderStyle = String(border.style);
    parsedClassNames.add(`border-${borderStyle}`);

    if (border?.color) {
      parsedClassNames.add(`border-[#${border.color.color}]`);
    }
  }
  if (border?.radius) {
    parsedClassNames.add(`rounded-${String(border.radius)}`);
  }
  return Array.from(parsedClassNames);
}

export function resolveShadowClassNames(shadow: Shadow): string[] {
  const parsedClassNames = new Set<string>();
  parsedClassNames.add(
    clsx({
      shadow: shadow === "base",
      [`shadow-${String(shadow)}`]: shadow !== "base",
    })
  );
  return Array.from(parsedClassNames);
}

export function resolveDynamicMediaClassNames(
  media: CustomMediaShape | VideoMediaShape
): string[] {
  if (!media?.src) {
    return [];
  }
  const parsedClassNames = new Set<string>();
  // responsive sources
  parsedClassNames.add(`bg-[url('${media.src}')]`);
  // parsedClassNames.add(`md:bg-[url('data:image/png;base64,${base64}')]`);
  // parsedClassNames.add(`lg:bg-[url('data:image/png;base64,${base64}')]`);
  // position
  media.position && parsedClassNames.add(`bg-${String(media.position)}`);
  // repeat
  media.repeat && parsedClassNames.add(`bg-${String(media.repeat)}`);
  // size
  media.size && parsedClassNames.add(`bg-${String(media.size)}`);
  return Array.from(parsedClassNames);
}

// font size auto transform map
const smallSizes = Array.from(["base", "sm", "xs"]);
const medSizes = Array.from(["4xl", "3xl", "2xl", "xl", "lg"]);
const largeSizes = Array.from(["6xl", "5xl"]);
const xlSizes = Array.from(["9xl", "8xl", "7xl"]);
export function mapFontSizeToAuto(classNames: string): string {
  // Leave everything <= base alone
  if (smallSizes.some((size) => classNames.includes(`text-${size}`))) {
    return classNames;
  }
  const _classNames = ` ${classNames}`;

  // Everything base < size <= 4xl is min capped to base on small screens
  for (let i = 0; i < medSizes.length; i++) {
    const sizeCheck = ` text-${medSizes[i]}`; // prepend w space to not catch variants
    if (_classNames.includes(sizeCheck)) {
      return _classNames
        .replaceAll(sizeCheck, ` sm:text-${medSizes[i]} text-${smallSizes[0]}`)
        .trim();
    }
  }

  // Everything 4xl < size <= 7xl is min capped to 4xl on small screens
  for (let i = 0; i < largeSizes.length; i++) {
    const sizeCheck = ` text-${largeSizes[i]}`; // prepend w space to not catch variants
    if (_classNames.includes(sizeCheck)) {
      return _classNames
        .replaceAll(sizeCheck, ` sm:text-${largeSizes[i]} text-${medSizes[0]}`)
        .trim();
    }
  }

  // Everything size > 7xl is min capped to 4xl on small screens
  for (let i = 0; i < xlSizes.length; i++) {
    const sizeCheck = ` text-${xlSizes[i]}`; // prepend w space to not catch variants
    if (_classNames.includes(sizeCheck)) {
      return _classNames
        .replaceAll(sizeCheck, ` sm:text-${xlSizes[i]} text-${largeSizes[0]}`)
        .trim();
    }
  }
  return classNames;
}

// font size auto transform map
export function mapFontSizeToAutoReverse(classNames: string): string {
  const _classNames = ` ${classNames}`;

  // Everything size > 7xl is min capped to 7xl on small screens
  for (let i = 0; i < xlSizes.length; i++) {
    if (_classNames.includes(` sm:text-${xlSizes[i]} text-${largeSizes[0]}`)) {
      return _classNames
        .replaceAll(
          ` sm:text-${xlSizes[i]} text-${largeSizes[0]}`,
          ` text-${xlSizes[i]}`
        )
        .trim();
    }
  }

  // Everything 4xl < size <= 7xl is min capped to 4xl on small screens
  for (let i = 0; i < largeSizes.length; i++) {
    if (_classNames.includes(` sm:text-${largeSizes[i]} text-${medSizes[0]}`)) {
      return _classNames
        .replaceAll(
          ` sm:text-${largeSizes[i]} text-${medSizes[0]}`,
          ` text-${largeSizes[i]}`
        )
        .trim();
    }
  }

  // Everything base < size <= 4xl is min capped to base on small screens
  for (let i = 0; i < medSizes.length; i++) {
    if (_classNames.includes(` sm:text-${medSizes[i]} text-${smallSizes[0]}`)) {
      return _classNames
        .replaceAll(
          ` sm:text-${medSizes[i]} text-${smallSizes[0]}`,
          ` text-${medSizes[i]}`
        )
        .trim();
    }
  }

  return classNames;
}

// type to auto transformer function
export const AUTO_TRANSFORM_MAP: Record<
  ComponentType | NavbarItemType,
  {
    func: (c: string) => string;
    reverse: (c: string) => string;
  }
> = {
  [ComponentType.Document]: { func: (c) => c, reverse: (c) => c },
  [ComponentType.Page]: { func: (c) => c, reverse: (c) => c },
  [ComponentType.Box]: {
    func: (c) => (c ?? "").replaceAll("flex-row", "sm:flex-row flex-col"),
    reverse: (c) => (c ?? "").replaceAll("sm:flex-row flex-col", "flex-row"),
  },
  [ComponentType.Button]: { func: (c) => c, reverse: (c) => c },
  [ComponentType.Text]: {
    func: mapFontSizeToAuto,
    reverse: mapFontSizeToAutoReverse,
  },
  [ComponentType.Image]: {
    func: (c) => c.replaceAll("w-", `w-full sm:w-`),
    reverse: (c) => c.replaceAll("w-full sm:w-", "w-"),
  },
  [ComponentType.Navbar]: { func: (c) => c, reverse: (c) => c },
  [NavbarItemType.NavbarItem]: {
    func: (c) => clsx(c, "sm:block hidden"),
    reverse: (c) => (c ?? "").replaceAll("sm:block hidden", ""),
  },
};

// Resolves mobile and normal classnames
export function resolveAllClassNamesFromValues(
  type: ComponentType | NavbarItemType,
  styles: Partial<Component["styles"]>,
  mobile:
    | {
        type: MobileResponsiveness;
        styles: Partial<Component["styles"]>;
      }
    | null
    | undefined = null,
  shouldCatch: boolean | undefined = true
): string {
  if (!mobile || !mobile.styles || mobile.type === "auto") {
    // Auto responsiveness tweaks only some values in the main styles
    return AUTO_TRANSFORM_MAP[type].func(
      resolveClassNameFromValues(styles, shouldCatch, true)
    );
  } else {
    return `${resolveClassNameFromValues(
      styles,
      shouldCatch
    )} ${resolveClassNameFromValues(mobile.styles, shouldCatch, true)}`;
  }
}

// generate tailwind friendly classnames
export function resolveClassNameFromValues(
  styles: Partial<Component["styles"]>,
  shouldCatch: boolean = true,
  isMobile = false
): string {
  const parsedClassNames = new Set();

  // Background and media
  for (const [key, style] of Object.entries(styles)) {
    if (key === "family") {
      parsedClassNames.add(`font-${style}`);
    } else if (key === "bg") {
      if (style) {
        if (style.type === MediaType.None) {
        } else if (style.type === MediaType.Color) {
          style.color && parsedClassNames.add(`bg-[#${style.color}]`);
        } else if ([MediaType.Custom, MediaType.Video].includes(style.type)) {
          resolveDynamicMediaClassNames(style).forEach((item) =>
            parsedClassNames.add(item)
          );
        } else if (style.type === MediaType.Gradient) {
          parsedClassNames.add(`bg-gradient-to-${style.direction}`);
          parsedClassNames.add(`from-[#${style.colorStart}]`);
          parsedClassNames.add(`to-[#${style.colorEnd}]`);
        }
      }
    } else if (key === "image") {
      resolveDynamicMediaClassNames(style).forEach((item) =>
        parsedClassNames.add(item)
      );
      if ("image" in styles) {
        const ratio = Number((styles.image?.aspectRatio || 1).toFixed(2));
        parsedClassNames.add(`aspect-[${ratio}]`);
      }
    } else if (key === "keepRatio") {
      // Only applies to images. Handled by w-full
    } else if (
      [
        "px",
        "py",
        "pt",
        "pr",
        "pb",
        "pl",
        "mx",
        "my",
        "mt",
        "mr",
        "mb",
        "ml",
        "w",
        "h",
      ].includes(key)
    ) {
      if (typeof style === "number") {
        parsedClassNames.add(`${key}-[calc(0.25rem*${style})]`);
      } else {
        parsedClassNames.add(`${key}-${style}`);
      }
    } else if (key === "maxW") {
      if (typeof style === "number") {
        parsedClassNames.add(`max-w-[calc(0.25rem*${style})]`);
      } else {
        parsedClassNames.add(`max-w-${style}`);
      }
    } else if (key === "align") {
      parsedClassNames.add(`text-${style}`);
    } else if (key === "contents") {
      parsedClassNames.add("flex");

      if (style.wrap) {
        parsedClassNames.add(`flex-${style.wrap}`);
      }

      if (style.direction === "row") {
        parsedClassNames.add("flex-row");
        parsedClassNames.add(
          clsx({
            "justify-start items-start": ["topleft"].includes(style.align),
            "justify-center items-start": ["topmiddle"].includes(style.align),
            "justify-end items-start": ["topright"].includes(style.align),
            "justify-start items-center": ["centerleft"].includes(style.align),
            "justify-center items-center": ["centermiddle"].includes(
              style.align
            ),
            "justify-end items-center": ["centerright"].includes(style.align),
            "justify-start items-end": ["bottomleft"].includes(style.align),
            "justify-center items-end": ["bottommiddle"].includes(style.align),
            "justify-end items-end": ["bottomright"].includes(style.align),
          })
        );
      } else {
        parsedClassNames.add("flex-col");
        parsedClassNames.add(
          clsx({
            "justify-start items-start": ["topleft"].includes(style.align),
            "justify-start items-center": ["topmiddle"].includes(style.align),
            "justify-start items-end": ["topright"].includes(style.align),
            "justify-center items-start": ["centerleft"].includes(style.align),
            "justify-center items-center": ["centermiddle"].includes(
              style.align
            ),
            "justify-center items-end": ["centerright"].includes(style.align),
            "justify-end items-start": ["bottomleft"].includes(style.align),
            "justify-end items-center": ["bottommiddle"].includes(style.align),
            "justify-end items-end": ["bottomright"].includes(style.align),
          })
        );
      }

      // Handles spacing === full in navbar contents
      if (style.gap === "full") {
        parsedClassNames.add(`gap-4`); // default gap
        if (style.direction === "row") {
          parsedClassNames.delete("justify-center");
          parsedClassNames.add("justify-between");
        } else {
          parsedClassNames.delete("justify-center");
          parsedClassNames.add("justify-between");
        }
      } else {
        parsedClassNames.add(`gap-[calc(0.25rem*${style.gap})]`);
      }
    } else if (key === "bg" && style && "type" in style) {
      if (style.type === MediaType.None) {
        parsedClassNames.add(`bg-none`);
      } else if (style.type === MediaType.Color) {
        style.color && parsedClassNames.add(`bg-[#${style.color}]`);
      } else if (style.type === MediaType.Gradient) {
        parsedClassNames.add(`bg-gradient-to-${style.direction}`);
        parsedClassNames.add(`from-[#${style.colorStart}]`);
        parsedClassNames.add(`to-[#${style.colorEnd}]`);
      }
    } else if (key === "shadow") {
      resolveShadowClassNames(style).forEach((item) =>
        parsedClassNames.add(item)
      );
    } else if (key === "border") {
      resolveBorderClassNames(style).forEach((item) =>
        parsedClassNames.add(item)
      );
    } else if (key === "hover") {
      if (style?.bg?.color) {
        parsedClassNames.add(`hover:bg-[#${style.bg.color}]`);
      }
      if (style?.shadow) {
        resolveShadowClassNames(style.shadow).forEach((item) =>
          parsedClassNames.add(`hover:${item}`)
        );
      }
      if (style?.border) {
        resolveBorderClassNames(style.border).forEach((item) =>
          parsedClassNames.add(`hover:${item}`)
        );
      }
      if (style?.color) {
        parsedClassNames.add(`hover:text-[#${style.color.color}]`);
      }
    } else if (key === "size") {
      parsedClassNames.add(`text-${style}`);
    } else if (key === "weight") {
      parsedClassNames.add(`font-${style}`);
    } else if (key === "lineSpacing") {
      parsedClassNames.add(`leading-${style}`);
      parsedClassNames.add(`sm:leading-${style}`);
    } else if (key === "letterSpacing") {
      parsedClassNames.add(`tracking-${style}`);
    } else if (key === "color") {
      parsedClassNames.add(`text-[#${style.color}]`);
    } else if (key === "image") {
    } else if (key === "position") {
      parsedClassNames.add(style);
    } else {
      if (shouldCatch) {
        const errMessage = `Cannot resolve classname for <key, value>: <${key}, ${JSON.stringify(
          style
        )}>`;
        console.error(errMessage);
        throw new Error(errMessage);
      }
    }
  }

  // Other styles
  for (const [key, style] of Object.entries(styles)) {
  }

  // Add sm breakpoint to all classnames
  const spacedOutParsedClassNames = Array.from(parsedClassNames)
    .join(" ")
    .split(" ");
  return clsx(
    isMobile
      ? spacedOutParsedClassNames
      : spacedOutParsedClassNames.map((className) =>
          BREAKPOINTS.some((bkpt: string) =>
            ((className ?? "") as string).startsWith(bkpt)
          )
            ? className
            : `sm:${className}`
        )
  );
}

export function createEmptyComponent(
  type: ComponentType.Document
): DocumentComponent;
export function createEmptyComponent(
  type: ComponentType.Navbar
): NavbarComponent;
export function createEmptyComponent(type: ComponentType.Page): PageComponent;
export function createEmptyComponent(type: ComponentType.Box): BoxComponent;
export function createEmptyComponent(
  type: ComponentType.Button
): ButtonComponent;
export function createEmptyComponent(type: ComponentType.Text): TextComponent;
export function createEmptyComponent(type: ComponentType.Image): ImageComponent;
export function createEmptyComponent(type: ComponentType): Component {
  if (type === ComponentType.Document) {
    const styles: DocumentComponent["styles"] = {
      pt: 0,
      pr: 0,
      pb: 0,
      pl: 0,
      bg: createEmptyMediaBackground(MediaType.Color),
    };
    return {
      type: ComponentType.Document,
      staticClassName: DOCUMENT_STATIC_CLASSNAME,
      className: resolveAllClassNamesFromValues(ComponentType.Document, styles),
      key: "new-page",
      slug: {
        slug: "",
        name: "",
      },
      page: createEmptyComponent(ComponentType.Page),
      styles,
    };
  }

  if (type === ComponentType.Page) {
    const styles: PageComponent["styles"] = {
      pt: 4,
      pr: 4,
      pb: 4,
      pl: 4,
      mt: 0,
      mr: 0,
      mb: 0,
      ml: 0,
      maxW: "full",
      bg: createEmptyMediaBackground(MediaType.Color, { color: null }),
      contents: {
        direction: "column",
        align: "centermiddle",
        gap: 1,
        wrap: "nowrap",
      },
    };
    return {
      type: ComponentType.Page,
      staticClassName: PAGE_STATIC_CLASSNAME,
      className: resolveAllClassNamesFromValues(ComponentType.Page, styles),
      children: [createEmptyComponent(ComponentType.Text)],
      styles,
    };
  }

  if (type === ComponentType.Navbar) {
    const values: NavbarComponent["values"] = {
      items: [],
      persist: false,
    };
    const styles: NavbarComponent["styles"] = {
      pt: 6,
      pr: 8,
      pb: 6,
      pl: 8,
      mt: 0,
      mr: 0,
      mb: 0,
      ml: 0,
      maxW: "full",
      h: "auto",
      bg: createEmptyMediaBackground(MediaType.Color, { color: "ffffff" }),
      shadow: null,
      border: null,
      contents: {
        direction: "row",
        align: "centermiddle",
        gap: "full",
        wrap: "wrap",
      },
      position: "sticky",
    };
    const mobile: NavbarComponent["mobile"] = {
      type: "auto",
      styles,
    };
    return {
      type: ComponentType.Navbar,
      staticClassName: NAVBAR_STATIC_CLASSNAME,
      className: resolveAllClassNamesFromValues(
        ComponentType.Navbar,
        styles,
        mobile
      ),
      styles,
      values,
      mobile,
    };
  }

  if (type === ComponentType.Box) {
    const styles: BoxComponent["styles"] = {
      pt: 4,
      pr: 4,
      pb: 4,
      pl: 4,
      mt: 0,
      mr: 0,
      mb: 0,
      ml: 0,
      maxW: "full",
      h: "auto",
      bg: createEmptyMediaBackground(MediaType.Color, { color: null }),
      shadow: null,
      border: null,
      contents: {
        direction: "column",
        align: "centermiddle",
        gap: 1,
        wrap: "wrap",
      },
    };
    const mobile: BoxComponent["mobile"] = {
      type: "auto",
      styles,
    };
    return {
      type: ComponentType.Box,
      staticClassName: BOX_STATIC_CLASSNAME,
      className: resolveAllClassNamesFromValues(
        ComponentType.Box,
        styles,
        mobile
      ),
      children: [],
      styles,
      mobile,
    };
  }

  if (type === ComponentType.Button) {
    const values: ButtonComponent["values"] = {
      to: {
        type: LinkToEnum.Page,
        label: "",
        slugInteractableId: [],
      },
    };
    const styles: ButtonComponent["styles"] = {
      family: "body",
      size: "base",
      weight: "medium",
      lineSpacing: "normal",
      letterSpacing: "normal",
      align: "center",
      pt: 1,
      pr: 4,
      pb: 1,
      pl: 4,
      mt: 0,
      mr: 0,
      mb: 0,
      ml: 0,
      w: "auto",
      h: "auto",
      bg: createEmptyMediaBackground(MediaType.Color, { color: "000000" }),
      shadow: null,
      border: {
        style: null,
        radius: "lg",
        width: 0,
        color: createEmptyMediaBackground(MediaType.Color, { color: "000000" }),
      },
      color: createEmptyMediaBackground(MediaType.Color, { color: "FFFFFF" }),
      hover: null,
    };
    return {
      type: ComponentType.Button,
      staticClassName: BUTTON_STATIC_CLASSNAME,
      className: resolveAllClassNamesFromValues(ComponentType.Button, styles),
      styles,
      values,
    };
  }

  if (type === ComponentType.Text) {
    const values: TextComponent["values"] = {
      text: "",
    };
    const styles: TextComponent["styles"] = {
      family: "body",
      size: "base",
      weight: "normal",
      lineSpacing: "normal",
      letterSpacing: "normal",
      align: "left",
      pt: 0,
      pr: 0,
      pb: 0,
      pl: 0,
      mt: 0,
      mr: 0,
      mb: 0,
      ml: 0,
      color: createEmptyMediaBackground(MediaType.Color, { color: "000000" }),
      shadow: null,
    };
    return {
      type: ComponentType.Text,
      staticClassName: TEXT_STATIC_CLASSNAME,
      className: resolveAllClassNamesFromValues(ComponentType.Text, styles),
      styles,
      values,
    };
  }

  if (type === ComponentType.Image) {
    const values: ImageComponent["values"] = {
      alt: "",
    };
    const styles: ImageComponent["styles"] = {
      mt: 0,
      mr: 0,
      mb: 0,
      ml: 0,
      keepRatio: true,
      w: "full",
      h: "full",
      shadow: null,
      border: null,
      image: createEmptyMediaBackground(MediaType.Custom),
    };
    const mobile: ImageComponent["mobile"] = {
      type: "auto",
      styles,
    };
    return {
      type: ComponentType.Image,
      className: resolveAllClassNamesFromValues(ComponentType.Image, styles),
      styles,
      values,
      mobile,
    };
  }

  throw new Error(
    `Generating an empty component for this type has not been implemented: ${type}`
  );
}

export function createEmptyNavbarItem(
  defaultNavbarItem: Partial<NavbarItemWithNew> = {}
): NavbarItemWithNew {
  const styles = {
    family: "body",
    size: "base",
    weight: "normal",
    lineSpacing: "normal",
    letterSpacing: "normal",
    color: createEmptyMediaBackground(MediaType.Color, { color: "000000" }),
    pt: 0,
    pr: 0,
    pb: 0,
    pl: 0,
    bg: null,
    border: null,
    hover: null,
  } as unknown as NavbarItem["styles"];
  const values: NavbarItem["values"] = {
    to: {
      type: LinkToEnum.Page,
      label: "",
      slugInteractableId: [],
    },
  };

  const newNavbarItem = {
    type: NavbarItemType.NavbarItem,
    values,
    styles,
    new: true,
    ...defaultNavbarItem,
  };

  return {
    ...newNavbarItem,
    className: resolveAllClassNamesFromValues(
      NavbarItemType.NavbarItem,
      newNavbarItem.styles
    ),
  };
}

export function createEmptyNavbarIconLinkPayload(): LinkIcon {
  const styles = {
    w: 10,
    image: null,
  };
  return {
    type: LinkToEnum.Icon,
    staticClassName: NAVBAR_ICON_STATIC_CLASSNAME,
    className: resolveAllClassNamesFromValues(
      NavbarItemType.NavbarItem,
      styles
    ),
    styles,
  };
}

export function createEmptyBorder(): Border {
  return {
    style: "solid",
    radius: "md",
    width: 0,
    color: createEmptyMediaBackground(MediaType.Color, { color: "000000" }),
  };
}

export function createEmptyMediaBackground(
  type: MediaType.Custom,
  defaults?: Record<string, any>
): CustomMediaShape;
export function createEmptyMediaBackground(
  type: MediaType.Gradient,
  defaults?: Record<string, any>
): GradientMediaShape;
export function createEmptyMediaBackground(
  type: MediaType.Video,
  defaults?: Record<string, any>
): VideoMediaShape;
export function createEmptyMediaBackground(
  type: MediaType.Color,
  defaults?: Record<string, any>
): ColorMediaShape;
export function createEmptyMediaBackground(
  type: MediaType,
  defaults: Record<string, any> = {}
): MediaShape {
  if (type === MediaType.Color) {
    return {
      type,
      color: "FFFFFF",
      ...defaults,
    };
  }
  if (type === MediaType.Gradient) {
    return {
      type,
      colorStart: "FFFFFF",
      colorEnd: "2E2EFF",
      direction: "r",
      ...defaults,
    };
  }
  if (type === MediaType.Video || type === MediaType.Custom) {
    return {
      type,
      src: null,
      aspectRatio: 1,
      dataUrl: null,
      position: "center",
      repeat: "no-repeat",
      size: "contain",
      attachment: "local",
      ...defaults,
    };
  }
  throw new Error(`This media type is not implemented: ${type}`);
}

interface DropdownOption extends Record<string, any> {
  label: any;
  value: any;
}

export function convertStyleValuesToOptions(
  values: Record<string, any> | [string, string, string | undefined][]
): DropdownOption[] {
  const iterated = Array.isArray(values) ? values : Object.entries(values);
  return iterated.map(([value, label, category]) => ({
    label,
    value,
    category,
  }));
}
