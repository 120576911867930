import {
  actions,
  afterMount,
  beforeUnmount,
  defaults,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from "kea";
import { DragEvent } from "react";
import { generateTemplateLogicKey } from "./templateDataUtils";
import { DocumentComponent } from "shared/lib/component.types";
import equal from "lodash.isequal";
import {
  LeftSidebarInnerTab,
  PopoverContentsTab,
  SelectedDocumentAndIndex,
  SidebarTab,
} from "shared/lib/types";
import { Dimension } from "../../../../components/hooks/useDimensions";
import {
  TemplateInteractableLogicProps,
  TemplateLogicProps,
} from "shared/lib/logic.types";
import type { templateControlLogicType } from "./templateControlLogicType";
import { windowValues } from "kea-window-values";
import { MIN_BREAKPOINTS } from "../tailwindtocss/utils";

export enum DisplayMode {
  Mobile = "mobile",
  Desktop = "desktop",
  Full = "full",
}

export const DISPLAY_MODE_TO_WIDTH: Record<DisplayMode, number> = {
  [DisplayMode.Mobile]: 390, // iPhone 14
  [DisplayMode.Desktop]: 1440, // Desktop
  [DisplayMode.Full]: 10000000, // Large number
};

export interface Translate extends Dimension {
  // offset on drag start. measures how far away initial mouse click is away from top left corner of button
  startOffsetX: number;
  startOffsetY: number;
  mouseX: number;
  mouseY: number;
}

// Default tailwind breakpoints: https://tailwindcss.com/docs/responsive-design#overview
const BREAKPOINTS: Record<string, number> = Object.fromEntries([
  ["sm", 640],
  ["md", 768],
  ["lg", 1024],
  ["xl", 1280],
  ["2xl", 1536],
]);

export function generateEmptyInteractableProps(
  props: TemplateLogicProps
): TemplateInteractableLogicProps {
  return {
    parentInteractableId: [],
    interactableId: [],
    logicProps: props,
  };
}

export const templateControlLogic = kea<templateControlLogicType>([
  props({} as TemplateLogicProps),
  path((key) => ["src", "components", "Template", "templateControlLogic", key]),
  key(generateTemplateLogicKey),
  defaults(({ props }) => ({
    globalMousePosition: { clientX: 0, clientY: 0 },
    selectedDocument: { document: null, index: 0 } as SelectedDocumentAndIndex, // Document that is selected from document picker. separate from popover
    hoveredPopoverInteractableProps: generateEmptyInteractableProps(props),
    activePopoverInteractableProps: generateEmptyInteractableProps(props),
    popoverInnerTab: PopoverContentsTab.Values as PopoverContentsTab,
    documentWidth: 0,
  })),
  actions(() => ({
    setDisplayMode: (mode: DisplayMode) => ({ mode }),
    setPopoverOpen: (
      isPopoverOpen: boolean,
      interactableProps?: TemplateInteractableLogicProps
    ) => ({ isPopoverOpen, interactableProps }),
    setPopoverHovered: (
      isPopoverHovered: boolean,
      interactableProps?: TemplateInteractableLogicProps
    ) => ({ isPopoverHovered, interactableProps }),
    setLeftSidebarTab: (tab: SidebarTab, innerTab?: LeftSidebarInnerTab) => ({
      tab,
      innerTab,
    }),
    setPopoverInnerTab: (innerTab: PopoverContentsTab) => ({ innerTab }),
    setBreakpoint: (width: number) => ({ width }),
    setSelectedDocument: (
      selectedDocument: DocumentComponent | null,
      index: number
    ) => ({ selectedDocument, index }),
    setGlobalMousePosition: (event: DragEvent<any>) => ({ event }),
    setDocumentWidth: (width: number) => ({ width }),
  })),
  reducers(({ props }) => ({
    documentWidth: {
      setDocumentWidth: (_, { width }) => width ?? 0,
    },
    globalMousePosition: {
      setGlobalMousePosition: (_, { event }) => ({
        clientX: event.clientX,
        clientY: event.clientY,
      }),
    },
    selectedDocument: {
      setSelectedDocument: (_, { selectedDocument, index }) => ({
        document: selectedDocument,
        index,
      }),
    },
    breakpoint: [
      "lg",
      {
        setBreakpoint: (_, { width }) => {
          if (isNaN(width)) {
            return "lg";
          }
          for (const [breakpoint, breakpointWidth] of Object.entries(
            BREAKPOINTS
          )) {
            if (width < breakpointWidth) {
              return breakpoint;
            }
          }
          return "lg";
        },
      },
    ],
    display: [
      DisplayMode.Full as DisplayMode,
      {
        setDisplayMode: (_, { mode }) => mode,
      },
    ],
    isPopoverOpen: [
      false,
      {
        setPopoverOpen: (_, { isPopoverOpen }) => isPopoverOpen,
      },
    ],
    leftSidebarTab: [
      {
        tab: null,
        innerTab: LeftSidebarInnerTab.Values,
      } as {
        tab: SidebarTab | null;
        innerTab: LeftSidebarInnerTab;
      },
      {
        setLeftSidebarTab: (prevLeftSidebarTab, { tab, innerTab }) => ({
          tab: innerTab
            ? tab ?? prevLeftSidebarTab.tab
            : tab === prevLeftSidebarTab.tab
            ? null
            : tab,
          innerTab: innerTab ?? prevLeftSidebarTab.innerTab,
        }),
      },
    ],
    popoverInnerTab: {
      setPopoverInnerTab: (_, { innerTab }) => innerTab,
    },
    hoveredPopoverInteractableProps: {
      setPopoverHovered: (_, { isPopoverHovered, interactableProps }) => {
        if (isPopoverHovered && interactableProps) {
          return interactableProps;
        }
        return generateEmptyInteractableProps(props);
      },
    },
    activePopoverInteractableProps: {
      setPopoverOpen: (_, { isPopoverOpen, interactableProps }) => {
        if (isPopoverOpen && interactableProps) {
          return interactableProps;
        }
        return generateEmptyInteractableProps(props);
      },
    },
  })),
  windowValues({
    isSmallScreen: (window: Window) => window.innerWidth < 640,
    windowWidth: (window: Window) => window.innerWidth,
  }),
  selectors(() => ({
    widthOfViewport: [
      (s) => [s.windowWidth, s.leftSidebarTab, s.isPopoverOpen],
      (windowWidth, leftSidebarTab, isPopoverOpen) => {
        return (
          windowWidth -
          (!!leftSidebarTab.tab ? 336 : 48) -
          (isPopoverOpen ? 288 : 0)
        );
      },
    ],
    breakpointWidthOffset: [
      (s) => [s.windowWidth, s.widthOfViewport, s.display],
      (windowWidth, widthOfViewport, display) =>
        display === DisplayMode.Mobile
          ? -1
          : display === DisplayMode.Desktop
          ? -2
          : windowWidth - widthOfViewport,
    ],
    templateProps: [
      () => [(_, props) => props],
      (props: TemplateLogicProps) => props,
    ],
    isMobileScreen: [
      (s) => [s.breakpoint],
      (breakpoint) => {
        return breakpoint === "sm";
      },
    ],
    screenEnabled: [
      (s) => [s.display],
      (display) => [DisplayMode.Mobile, DisplayMode.Desktop].includes(display),
    ],
    isOverflowing: [
      (s) => [s.display, s.screenEnabled, s.widthOfViewport],
      (display, screenEnabled, widthOfViewport) => {
        return (
          screenEnabled && DISPLAY_MODE_TO_WIDTH[display] > widthOfViewport
        );
      },
    ],
    checkSelected: [
      (s) => [s.activePopoverInteractableProps],
      (activePopoverInteractableProps) =>
        (interactableId: TemplateInteractableLogicProps["interactableId"]) =>
          equal(activePopoverInteractableProps.interactableId, interactableId),
    ],
    checkHovered: [
      (s) => [s.hoveredPopoverInteractableProps],
      (hoveredPopoverInteractableProps) =>
        (interactableId: TemplateInteractableLogicProps["interactableId"]) =>
          equal(hoveredPopoverInteractableProps.interactableId, interactableId),
    ],
  })),
  listeners(({ actions, props }) => ({
    setSelectedDocument: ({ index }) => {
      // Set document has been selected, always open the document popover
      actions.setPopoverOpen(true, {
        parentInteractableId: [],
        interactableId: ["documents", index],
        logicProps: props,
      });
    },
  })),
  afterMount(({ actions }) => {
    // Add dragover event to global window to capture mouse position globally. Required for Firefox which doesn't support drag event...
    // https://stackoverflow.com/questions/11656061/why-is-event-clientx-incorrectly-showing-as-0-in-firefox-for-dragend-event/63365865#63365865
    window.addEventListener(
      "dragover",
      actions.setGlobalMousePosition as unknown as EventListenerOrEventListenerObject
    );
  }),
  beforeUnmount(({ actions }) => {
    window.removeEventListener(
      "dragover",
      actions.setGlobalMousePosition as unknown as EventListenerOrEventListenerObject
    );
  }),
]);
