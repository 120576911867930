import {
  actions,
  afterMount,
  beforeUnmount,
  kea,
  path,
  reducers,
  listeners,
  selectors,
} from "kea";
import { router } from "kea-router";
import { Plan, UserMetaData } from "shared/lib/types";
import supabase from "./supabase";
import { PRIVATE_URLS, SceneKey, urls } from "shared/lib/urls";
import type { userLogicType } from "./userLogicType";
import { Table } from "shared/lib/constants";
import api from "./api";
import { loaders } from "kea-loaders";
import type {
  Session,
  User,
} from "@supabase/gotrue-js/dist/module/lib/types.d";
import posthog from "posthog-js";
import { signupModalLogic } from "../components/SignupModal/signupModalLogic";

function isFromLoginRedirect(): boolean {
  return (
    router.values.currentLocation.pathname === urls.dashboard() &&
    router.values.hashParams.access_token
  );
}

export const userLogic = kea<userLogicType>([
  path(["src", "helpers", "userLogic"]),
  actions(() => ({
    setUser: (user: User | null) => ({ user }),
    setUserMetaData: (metadata: UserMetaData | null) => ({ metadata }),
    fetchUserMetaData: (user: User) => ({ user }),
    logout: true,
    upgradePlan: true,
    manageBilling: true,
  })),
  loaders(({ values }) => ({
    paymentLink: [
      "",
      {
        upgradePlan: async (_, breakpoint) => {
          await breakpoint(100);
          const { response } = await api.subscription.upgrade();
          breakpoint();
          if (response.status === "error" || !response.result) {
            throw new Error(
              response.message || "There was an error creating the payment link"
            );
          }
          window.location.href = response.result;
          return response.result ?? "";
        },
      },
    ],
    manageBillingLink: [
      "",
      {
        manageBilling: async (_, breakpoint) => {
          await breakpoint(100);
          const { response } = await api.subscription.manage();
          breakpoint();
          if (response.status === "error" || !response.result) {
            throw new Error(
              response.message ||
                "There was an error creating the manage billing link"
            );
          }
          window.location.href = response.result;
          return response.result ?? "";
        },
      },
    ],
    userMetaData: [
      null as UserMetaData | null,
      {
        fetchUserMetaData: async ({ user }, breakpoint) => {
          await breakpoint(100);
          const { data, error } = await supabase
            .from(Table.Users)
            .select()
            .eq("id", user.id)
            .limit(1)
            .single();
          breakpoint();
          if (error) {
            throw new Error(error.message);
          }
          return data as UserMetaData;
        },
        setUser: ({ user }) => (!!user ? values.userMetaData : user),
        logout: () => null,
      },
    ],
  })),
  reducers(() => ({
    user: [
      null as User | null,
      {
        setUser: (_, { user }) => user,
        logout: () => null,
      },
    ],
  })),
  selectors(() => ({
    userPlan: [
      (s) => [s.userMetaData],
      (metaData) => {
        return metaData?.plan ?? Plan.Core;
      },
    ],
  })),
  listeners(() => ({
    logout: () => {
      supabase.auth.signOut();
      router.actions.push(urls.home());
    },
    setUser: () => {
      signupModalLogic.findMounted()?.actions?.setModalOpen(false);
    },
  })),
  selectors(() => ({
    isAuthenticated: [(s) => [s.user], (user) => !!user],
  })),
  afterMount(async ({ actions, cache, values }) => {
    // Redirect to home if user isn't authenticated but private route is requested
    const {
      data: { user: currentUser },
    } = await supabase.auth.getUser();
    if (
      !currentUser &&
      PRIVATE_URLS.includes(
        router.values.currentLocation?.pathname?.slice(1) as SceneKey
      ) &&
      !isFromLoginRedirect()
    ) {
      router.actions.push(urls.home());
    }

    // Check if user is already logged in
    if (currentUser) {
      actions.setUser(currentUser);
      if (!values.userMetaData) {
        actions.fetchUserMetaData(currentUser);
      }
    }

    cache.unsubscribeOnAuthStateChange = supabase.auth.onAuthStateChange(
      (_: any, session: Session | null) => {
        const nextUser = session?.user ?? null;
        actions.setUser(nextUser);
        if (!nextUser) {
          router.actions.push(urls.home());
          return;
        }

        // Identify w/ posthog
        posthog.identify(nextUser.id);

        // If user meta isn't loaded, fetch it
        if (!values.userMetaData) {
          actions.fetchUserMetaData(nextUser);
        }
      }
    );
  }),
  beforeUnmount(({ cache }) => {
    cache.unsubscribeOnAuthStateChange?.();
  }),
]);
