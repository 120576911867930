import { actions, kea, path, reducers } from "kea";
import type { signupModalLogicType } from "./signupModalLogicType";

export const signupModalLogic = kea<signupModalLogicType>([
  path(["src", "components", "SignupModal", "signupModalLogic"]),
  actions(() => ({
    setModalOpen: (open: boolean) => ({ open }),
  })),
  reducers(() => ({
    open: [
      false,
      {
        setModalOpen: (_, { open }) => open,
      },
    ],
  })),
]);
