import { PublishWebsiteActions, Theme } from "shared/lib/types";
import {
  DOMAIN_URL,
  IS_DEVELOPMENT,
  PreviewType,
  removeHttp,
} from "shared/lib/constants";
import cloneDeep from "lodash.clonedeep";

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;

export const DOMAIN_REGEX =
  /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

// Checks default domains
export const DOMAIN_NAME_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]$/;

export function generateTimeAwareGreeting(): string {
  const today = new Date();
  const curHr = today.getHours();

  if (curHr < 12) {
    return "Good morning";
  } else if (curHr < 18) {
    return "Good afternoon";
  }
  return "Good evening";
}

export function nFormatter(num: number, digits: number = 0): string {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export function capitalizeFirstLetter(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

// Converts an ArrayBuffer directly to base64, without any intermediate 'convert to string then
// use window.btoa' step. According to my tests, this appears to be a faster approach:
// http://jsperf.com/encoding-xhr-image-data/5
export function arrayBufferToBase64(arrayBuffer: number[]): string {
  let base64 = "";
  const encodings =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

  const bytes = new Uint8Array(arrayBuffer);
  const byteLength = bytes.byteLength;
  const byteRemainder = byteLength % 3;
  const mainLength = byteLength - byteRemainder;

  let a, b, c, d;
  let chunk;

  // Main loop deals with bytes in chunks of 3
  for (let i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + "==";
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + "=";
  }

  return base64;
}

export function trim(s: string, c: string): string {
  if (c === "]") {
    c = "\\]";
  }
  if (c === "^") {
    c = "\\^";
  }
  if (c === "\\") {
    c = "\\\\";
  }
  return s.replace(new RegExp("^[" + c + "]+|[" + c + "]+$", "g"), "");
}

export function isJson(s: string): boolean {
  try {
    JSON.parse(s);
  } catch (e) {
    return false;
  }
  return true;
}

export function createLiveDomain(domain: string): string {
  if (IS_DEVELOPMENT && domain.endsWith("localhost")) {
    const urlObject = new URL(
      `https://${domain.replace(".localhost", "")}.simplelanding.alexkim.dev`
    );
    return urlObject.toString();
  }
  const urlObject = new URL(`https://${domain}`);
  return urlObject.toString();
}

// This function will output the lines from the script
// AS is runs, AND will return the full combined output
// as well as exit code when it's done (using the callback).
// https://stackoverflow.com/questions/14332721/node-js-spawn-child-process-and-get-terminal-output-live
export async function run_script(
  command: string,
  args: string[],
  callback: (a: any) => void
): Promise<void> {
  const spawn = (await import("child_process")).spawn;

  return new Promise(function (resolve, reject) {
    const process = spawn(command, args);
    let result = "";
    process.stdout.on("data", function (data: any) {
      result += data.toString();
    });
    process.on("close", function () {
      // Should probably be 'exit', not 'close'
      // *** Process completed
      callback(result);
      resolve();
    });
    process.on("error", function (err) {
      // *** Process creation failed
      console.error(err);
      reject(err);
    });
  });
}

export function getSystemTheme(): Theme {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    return Theme.Dark;
  }
  return Theme.Light;
}

export function getPreviewFilePath(
  previewType: PreviewType,
  id: string | number
): string {
  return `${previewType}/${id}.jpg`;
}

export function pickTextColorBasedOnBgColorAdvanced(bgColor: string): string {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? "#000000" : "#FFFFFF";
}

export function slugify(text: string): string {
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[-]+/g, "-")
    .replace(/[^\w-]+/g, "");
}

export function setDeep(
  tree: Record<string, any>,
  path: (string | number)[],
  value: any
): typeof tree {
  const obj = cloneDeep(tree);
  path.reduce((a, b, level) => {
    if (level === path.length - 1) {
      a[b] = value;
      return value;
    }
    return a[b];
  }, obj);

  return obj;
}

export function isNumeric(str: any): boolean {
  // we only process strings!
  if (typeof str != "string") {
    return false;
  }
  return (
    !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function determineActionFromDomain(
  domain: string | undefined | null
): PublishWebsiteActions {
  return domain?.endsWith(DOMAIN_URL) ||
    domain?.endsWith(".simplelanding.alexkim.dev")
    ? PublishWebsiteActions.PublishToDefaultDomain
    : PublishWebsiteActions.PublishToCustomDomain;
}

export function stripDefaultDomain(domain: string | undefined | null): string {
  return removeHttp(
    domain
      ?.replaceAll(`.${DOMAIN_URL}`, "")
      ?.replaceAll(`.simplelanding.alexkim.dev`, "") ?? ""
  );
}

export function createDefaultDomain(domain: string | undefined | null): string {
  return `${stripDefaultDomain(domain)}${
    IS_DEVELOPMENT ? ".simplelanding.alexkim.dev" : ".simplelanding.app"
  }`;
}

export function isValidDomain(domain: string | undefined | null): boolean {
  return DOMAIN_REGEX.test(domain ?? "");
}

export function isValidDefaultDomain(
  domain: string | undefined | null
): boolean {
  return DOMAIN_NAME_REGEX.test(domain ?? "");
}
