import { VeryBasic } from "unsplash-js/dist/methods/photos/types";
import { AuthorizationHeaderKey, SERVER_URL } from "shared/lib/constants";
import supabase from "./supabase";
import {
  ApiResponse,
  BlogPost,
  ComponentRow,
  DeployPayload,
  PreviewObject,
  PublishWebsiteFormData,
  SaveComponentFormData,
  SaveWebsiteFormData,
  TemplateRow,
  UploadFileFormData,
  UploadFileResponse,
  WebsiteRow,
  WebsiteType,
} from "shared/lib/types";
import { encodeParams } from "kea-router";
import { TemplateLogicProps } from "shared/lib/logic.types";

async function makeApiRequest<T>({
  url,
  method,
  body = {},
  headers = {},
}: {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  body?: Record<string, any>;
  headers?: Record<string, any>;
}): Promise<ApiResponse<T>> {
  const nextHeaders = new Headers({
    "content-type": "application/json",
    ...headers,
  });

  const {
    data: { session },
  } = await supabase.auth.getSession();
  if (session) {
    nextHeaders.append(
      AuthorizationHeaderKey.AccessToken,
      `Bearer ${session.access_token}`
    );
    nextHeaders.append(
      AuthorizationHeaderKey.RefreshToken,
      `Bearer ${session.refresh_token}`
    );
  }

  const response = await fetch(`${SERVER_URL}${url}`, {
    method,
    headers: nextHeaders,
    ...(["POST", "PUT"].includes(method) ? { body: JSON.stringify(body) } : {}),
  });

  return await response.json();
}

const rawApi = {
  get: async <T>(url: string, headers = {}) => {
    return await makeApiRequest<T>({
      method: "GET",
      url,
      headers,
    });
  },
  create: async <T>(url: string, body = {}, headers = {}) => {
    return await makeApiRequest<T>({
      method: "POST",
      url,
      body,
      headers,
    });
  },
  update: async <T>(url: string, body = {}, headers = {}) => {
    return await makeApiRequest<T>({
      method: "PUT",
      url,
      body,
      headers,
    });
  },
  delete: async <T>(url: string, headers = {}) => {
    return await makeApiRequest<T>({
      method: "DELETE",
      url,
      headers,
    });
  },
};

const api = {
  ...rawApi,
  publish: {
    post: async (
      data: PublishWebsiteFormData
    ): Promise<{
      response: ApiResponse<WebsiteRow>;
    }> => {
      // Hits publish website endpoint which starts a synchronous task in server
      const response = await rawApi.create<WebsiteRow>("/publish", data);
      return { response };
    },
  },
  publishPreview: {
    post: async (
      data: Partial<PublishWebsiteFormData>
    ): Promise<{
      response: ApiResponse<DeployPayload[]>;
    }> => {
      // Hits publish preview website endpoint which calls build-preview edge function
      const response = await rawApi.create<DeployPayload[]>(
        "/publish_preview",
        data
      );
      return { response };
    },
  },
  photos: {
    get: async ({
      photoId,
    }: {
      photoId: string;
    }): Promise<{
      response: ApiResponse<VeryBasic>;
    }> => {
      // Get site data from domain
      const response = await rawApi.get<VeryBasic>(
        `/photos${encodeParams({ photoId }, "?")}`,
        {}
      );
      return { response };
    },
  },
  previews: {
    list: async (props: {
      websiteIds?: string[];
      templateIds?: string[];
    }): Promise<{
      response: ApiResponse<PreviewObject[]>;
    }> => {
      // Get site data from domain
      const response = await rawApi.get<PreviewObject[]>(
        `/previews${encodeParams(props, "?")}`
      );
      return { response };
    },
  },
  templates: {
    list: async (): Promise<{
      response: ApiResponse<TemplateRow[]>;
    }> => {
      // Get site data from domain
      const response = await rawApi.get<TemplateRow[]>(`/templates`);
      return { response };
    },
  },
  sites: {
    get: async (
      props: TemplateLogicProps
    ): Promise<{
      response: ApiResponse<WebsiteType>;
    }> => {
      // Get site data from domain
      const response = await rawApi.get<WebsiteType>(
        `/sites${encodeParams(props, "?")}`
      );
      return { response };
    },
    update: async (
      data: SaveWebsiteFormData
    ): Promise<{
      response: ApiResponse<WebsiteRow>;
    }> => {
      const response = await rawApi.update<WebsiteRow>("/sites", data);
      return { response };
    },
    list: async (): Promise<{
      response: ApiResponse<WebsiteRow[]>;
    }> => {
      // List all websites for user
      const response = await rawApi.get<WebsiteRow[]>(`/sites`);
      return { response };
    },
  },
  components: {
    create: async (
      data: SaveComponentFormData
    ): Promise<{
      response: ApiResponse<ComponentRow>;
    }> => {
      const response = await rawApi.create<ComponentRow>(`/components`, data);
      return { response };
    },
    get: async (
      id: ComponentRow["id"]
    ): Promise<{
      response: ApiResponse<ComponentRow>;
    }> => {
      const response = await rawApi.get<ComponentRow>(
        `/components${encodeParams({ id }, "?")}`
      );
      return { response };
    },
    delete: async (
      id: ComponentRow["id"]
    ): Promise<{
      response: ApiResponse<ComponentRow>;
    }> => {
      const response = await rawApi.delete<ComponentRow>("/components", id);
      return { response };
    },
    list: async (
      websiteId: ComponentRow["website_id"]
    ): Promise<{
      response: ApiResponse<ComponentRow[]>;
    }> => {
      // List all components for website
      const response = await rawApi.get<ComponentRow[]>(
        `/components${encodeParams({ websiteId }, "?")}`
      );
      return { response };
    },
  },
  subscription: {
    upgrade: async (): Promise<{
      response: ApiResponse<string>;
    }> => {
      const response = await rawApi.create<string>("/subscription", {
        body: "upgrade",
      });
      return { response };
    },
    manage: async (): Promise<{
      response: ApiResponse<string>;
    }> => {
      const response = await rawApi.create<string>("/subscription", {
        body: "manage",
      });
      return { response };
    },
  },
  blogPosts: {
    get: async (
      slug: string
    ): Promise<{
      response: ApiResponse<BlogPost>;
    }> => {
      // Get blog post
      const response = await rawApi.get<BlogPost>(
        `/blog_posts${encodeParams({ slug }, "?")}`
      );
      return { response };
    },
    list: async (): Promise<{
      response: ApiResponse<BlogPost[]>;
    }> => {
      // List all blog posts
      const response = await rawApi.get<BlogPost[]>(`/blog_posts`);
      return { response };
    },
  },
  file: {
    post: async (
      data: UploadFileFormData
    ): Promise<{
      response: ApiResponse<UploadFileResponse>;
    }> => {
      // Upload base64 png file to supabase storage
      const response = await rawApi.create<UploadFileResponse>(`/file`, data);
      return { response };
    },
  },
};

export default api;
