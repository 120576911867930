import clsx from "clsx";
import { A } from "kea-router";
import {
  ButtonHTMLAttributes,
  cloneElement,
  ReactNode,
  ReactElement,
} from "react";
import { Spinner } from "./Spinner";

export interface ButtonProps extends ButtonHTMLAttributes<any> {
  size: "xs" | "small" | "medium" | "large";
  variant:
    | "primary"
    | "secondary"
    | "outlined"
    | "primary-dark"
    | "stealth"
    | "minimal";
  children: ReactNode;
  to?: string;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  loading?: boolean;
  onClick?: () => void;
  fullWidth?: boolean;
  rounded?: "xs" | "sm" | "base" | "md" | "lg" | "full" | "none";
  animated?: boolean;
  external?: boolean;
  borderThickness?: 1 | 2 | 3;
}

export function Button({
  className,
  size,
  variant,
  children,
  to,
  leftIcon,
  rightIcon,
  loading,
  disabled,
  onClick = () => {},
  fullWidth = false,
  rounded = "md",
  animated = true,
  external = false,
  borderThickness = 2,
  ...props
}: ButtonProps): JSX.Element {
  const buttonProps = {
    className: clsx(
      "w-content group relative flex shrink-0 items-center justify-center",
      {
        "h-8 px-4": size === "xs",
        "h-10 px-4": size === "small",
        "h-12 px-6": size === "medium",
        "h-14 px-8": size === "large",
        "w-full": fullWidth,
      },
      {
        "text-white": ["primary", "minimal"].includes(variant),
        "text-black": !["primary", "minimal"].includes(variant),
      },
      className
    ),
    ...props,
  };

  const textProps = {
    className: clsx(
      "whitespace-nowrap text-center",
      {
        "text-sm": size === "xs",
        "text-base sm:text-lg": size === "large",
      },
      {
        "font-medium": size !== "xs",
      }
    ),
  };

  const iconProps = {
    size: {
      xs: "1rem",
      small: "1.2rem",
      medium: "1.3rem",
      large: "1.5rem",
    }[size],
    className: "float-right",
  };

  const noInteraction = disabled || loading;

  children = (
    <div
      className={clsx(
        "Button__content",
        "flex flex-row items-center justify-center gap-2"
      )}
    >
      {(loading || leftIcon) && (
        <div className="Button__icon Button__left-icon w-6 text-right">
          {loading ? (
            <Spinner
              size="small"
              variant={variant === "primary" ? "inverse" : "primary"}
              className="float-right"
            />
          ) : (
            leftIcon && cloneElement(leftIcon, iconProps)
          )}
        </div>
      )}
      <div className="Button__text">{children}</div>
      {rightIcon && (
        <div className="Button__icon Button__right-icon w-6">
          {cloneElement(rightIcon, iconProps)}
        </div>
      )}
    </div>
  );

  const inner = (
    <>
      <div
        className={clsx(
          "absolute h-full w-full transition-transform",
          `border-${borderThickness}`,
          animated && "group-hover:scale-105",
          rounded === "base" ? "rounded" : `rounded-${rounded}`,
          {
            "border-transparent bg-indigo-600 group-hover:bg-indigo-800":
              variant === "primary",
            "border-transparent bg-indigo-800": variant === "primary-dark",
            "border-transparent bg-yellow-500": variant === "secondary",
            "border-black bg-white": variant === "outlined",
            "border-transparent bg-transparent": variant === "stealth",
            "border-white bg-transparent": variant === "minimal",
            "cursor-not-allowed opacity-50": noInteraction,
          }
        )}
      />
      <span
        className={clsx(textProps.className, "absolute w-full", {
          "cursor-not-allowed": noInteraction,
        })}
      >
        {children}
      </span>
      <span className={clsx(textProps.className, "invisible")}>{children}</span>
    </>
  );

  if (to) {
    return (
      <A href={to} {...buttonProps} target={external ? "_blank" : undefined}>
        {inner}
      </A>
    );
  }

  return (
    <button disabled={noInteraction} onClick={onClick} {...buttonProps}>
      {inner}
    </button>
  );
}
