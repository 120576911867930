import posthog from "posthog-js";
import { CLIENT_URL, SERVER_URL } from "shared/lib/constants";

const POSTHOG_PROJECT_API_KEY = process.env.REACT_APP_POSTHOG_PROJECT_API_KEY;

export function createPostHogClient(): void {
  if (!POSTHOG_PROJECT_API_KEY) {
    const message = "Error: PostHog environment variables aren't set!";
    console.error(message);
    throw new Error(message);
  }

  posthog.init(POSTHOG_PROJECT_API_KEY, {
    api_host: `${CLIENT_URL}/ingest`,
  });
}
