import { actions, kea, listeners, path, reducers, selectors } from "kea";
import { urlToAction } from "kea-router";
import { SceneKey, urlsToScenes } from "shared/lib/urls";
import type { sceneLogicType } from "./sceneLogicType";
import { Theme } from "shared/lib/types";
import { getSystemTheme } from "../helpers/utils";

export const sceneLogic = kea<sceneLogicType>([
  path(["src", "routerLogic"]),
  actions({
    setScene: (scene: SceneKey, params: Record<string, any>) => ({
      scene,
      params,
    }),
    setTheme: (theme: Theme) => ({ theme }),
  }),
  reducers({
    scene: [
      SceneKey.Home as SceneKey,
      {
        setScene: (_, payload) => payload.scene,
      },
    ],
    params: [
      {},
      {
        setScene: (_, payload) => payload.params || {},
      },
    ],
    theme: [
      (localStorage.getItem("theme") ?? Theme.System) as Theme, // only affects private scenes
      {
        setTheme: (_, { theme }) => theme,
      },
    ],
  }),
  selectors(() => ({
    isBuilderPage: [
      // Can be dark mode
      (s) => [s.scene],
      (scene) => [SceneKey.Dashboard, SceneKey.Intake].includes(scene),
    ],
    processedTheme: [
      (s) => [s.theme],
      (theme) => {
        if (theme === Theme.System) {
          return getSystemTheme();
        }
        return theme;
      },
    ],
  })),
  listeners(() => ({
    setTheme: ({ theme }) => {
      if (theme === Theme.System) {
        localStorage.removeItem("theme");
      } else {
        localStorage.theme = theme;
      }
    },
  })),
  urlToAction(({ actions }) => {
    return Object.fromEntries(
      Object.entries(urlsToScenes).map(([path, scene]) => {
        return [path, (params) => actions.setScene(scene as SceneKey, params)];
      })
    );
  }),
]);
