// Hard coded default components in library

import { ComponentType } from "shared/lib/component.types";
import { LibraryTab } from "shared/lib/types";
import { CgFile, CgFormatText, CgImage, CgToolbarTop } from "react-icons/cg";
import { IconType } from "react-icons";
import { LuBoxSelect, LuCircle, LuImage, LuText } from "react-icons/lu";

export interface DefaultBlockType {
  type: ComponentType;
  tags: string[];
  Icon: IconType;
}

export const DEFAULT_BLOCKS: DefaultBlockType[] = [
  {
    type: ComponentType.Document,
    tags: [LibraryTab.Layout],
    Icon: CgFile,
  },
  {
    type: ComponentType.Box,
    tags: [LibraryTab.Layout],
    Icon: LuBoxSelect,
  },
  {
    type: ComponentType.Navbar,
    tags: [LibraryTab.Layout],
    Icon: CgToolbarTop,
  },
  {
    type: ComponentType.Button,
    tags: [LibraryTab.Basic],
    Icon: LuCircle,
  },
  {
    type: ComponentType.Text,
    tags: [LibraryTab.Basic],
    Icon: LuText,
  },
  {
    type: ComponentType.Image,
    tags: [LibraryTab.Basic],
    Icon: LuImage,
  },
];
