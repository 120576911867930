import { useMountedLogic, useValues } from "kea";
import { Suspense } from "react";
import { userLogic } from "../../helpers/userLogic";
import { sceneLogic } from "../sceneLogic";
import { Loading } from "../Loading";
import { UpsellModal } from "../../components/UpsellModal/UpsellModal";
import clsx from "clsx";
import { Theme } from "shared/lib/types";
import { ToastContainer } from "../../components/ToastContainer/ToastContainer";
import { scenes } from "../../helpers/scenes";
import { SceneKey } from "shared/lib/urls";

function App(): JSX.Element {
  useMountedLogic(userLogic);
  const { scene, params, isBuilderPage, processedTheme } =
    useValues(sceneLogic);
  const Scene = (scene && scenes?.[scene]) || scenes[SceneKey.Error];

  return (
    <div
      id="app-root"
      className={clsx(isBuilderPage && processedTheme === Theme.Dark && "dark")}
    >
      <Suspense fallback={isBuilderPage ? <></> : <Loading />}>
        <Scene {...params} />
      </Suspense>
      <UpsellModal />
      <ToastContainer />
    </div>
  );
}

export default App;
