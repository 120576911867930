import clsx from "clsx";
import { useActions, useValues } from "kea";
import { Plan, PlanData } from "shared/lib/types";
import { urls } from "shared/lib/urls";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { Tooltip } from "../Tooltip";
import { upsellModalLogic } from "./upsellModalLogic";
import { DOMAIN_URL } from "shared/lib/constants";

const HIGHLIGHTED_PLAN = Plan.Pro;

export const PLANS: Record<Plan, PlanData> = {
  [Plan.Core]: {
    label: "Core",
    price: 0,
    features: [
      {
        label: (
          <>
            <code className="break-all rounded-md bg-indigo-900 px-2 py-1 text-sm">
              .{DOMAIN_URL}
            </code>{" "}
            URLs
          </>
        ),
        description: (
          <>
            Publish your websites on
            <code className="break-all rounded-md px-2 py-1 text-xs">
              .{DOMAIN_URL}
            </code>{" "}
            domains.
          </>
        ),
      },
      {
        label: "5 websites",
      },
      {
        label: "Yes branding",
        description:
          "SimpleLanding branding will be placed in the footer of the website.",
      },
      {
        label: "5 GB total bandwidth",
        description:
          "The overall network traffic across all your websites is limited to 5GB per month.",
      },
    ],
  },
  [Plan.Pro]: {
    label: "Pro",
    price: 19,
    features: [
      {
        label: "Custom URLs",
        description: "Publish your websites on your own domains.",
      },
      {
        label: "10 websites",
      },
      {
        label: "No branding",
      },
      {
        label: "20 GB total bandwidth",
        description:
          "The overall network traffic across all your websites is limited to 20GB per month.",
      },
    ],
  },
};

export function UpsellModal(): JSX.Element {
  const { open } = useValues(upsellModalLogic);
  const { closeModal } = useActions(upsellModalLogic);

  return (
    <Modal
      title="Upgrade to Pro!"
      open={open}
      closeModal={closeModal}
      variant="dark"
    >
      <div className="flex flex-col gap-2">
        <div className="my-2 max-w-sm self-center text-center text-sm leading-6">
          Instantly upgrade to unlock Pro-exclusive features such as custom
          domains, more websites, and more.
        </div>
        <div className="flex flex-row gap-2">
          {Object.entries(PLANS).map(([plan, { label, price, features }]) => (
            <div
              key={plan}
              className={clsx(
                "flex w-1/2 flex-col gap-1 rounded-md border-2",
                plan === HIGHLIGHTED_PLAN ? "border-white" : "border-indigo-800"
              )}
            >
              <div className="my-4 rounded-md text-center ">
                <h5 className="text-lg font-bold uppercase tracking-widest">
                  {label}
                </h5>
                <div>{price === 0 ? "Free" : `$${price}/year`}</div>
              </div>
              <div className="flex flex-col divide-y divide-indigo-600 bg-indigo-800 px-4 py-2">
                {features.map(({ label, description }, i) => {
                  const hasTooltip = !!description;
                  const content = (
                    <div
                      className={clsx(
                        "overflow-hidden overflow-ellipsis text-center",
                        hasTooltip &&
                          "cursor-help underline decoration-indigo-300 decoration-dashed underline-offset-4"
                      )}
                    >
                      {label}
                    </div>
                  );
                  return (
                    <div
                      key={i}
                      className="flex h-20 flex-col items-center justify-center"
                    >
                      {hasTooltip ? (
                        <Tooltip content={description}>{content}</Tooltip>
                      ) : (
                        content
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-row gap-2">
          {Object.entries(PLANS).map(([plan]) => (
            <div
              key={plan}
              className={clsx(
                "flex w-1/2 flex-col gap-1 rounded-md border-2 border-transparent"
              )}
            >
              {plan !== Plan.Core && (
                <Button
                  size="medium"
                  variant="secondary"
                  rounded="md"
                  external
                  to={urls.settings()}
                >
                  <span className="font-bold">Select plan</span>
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}
