import { lazy, LazyExoticComponent } from "react";
import { SceneKey } from "shared/lib/urls";

export const scenes: Record<
  SceneKey,
  LazyExoticComponent<(p: any) => JSX.Element>
> = {
  [SceneKey.Error]: lazy(() => import("../scenes/404/404")),
  [SceneKey.Home]: lazy(() => import("../scenes/Home/Home")),
  [SceneKey.Login]: lazy(() => import("../scenes/Auth/Login")),
  [SceneKey.Signup]: lazy(() => import("../scenes/Auth/Signup")),
  [SceneKey.Intake]: lazy(() => import("../scenes/Intake/Intake")),
  [SceneKey.Preview]: lazy(() => import("../scenes/Preview/Preview")),
  [SceneKey.Dashboard]: lazy(() => import("../scenes/Private/DashboardLayout")),
  [SceneKey.Changelog]: lazy(() => import("../scenes/Changelog/Changelog")),
};
