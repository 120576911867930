import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./helpers/reportWebVitals";
import { sendToVercelAnalytics } from "./helpers/vitals";
import { routerPlugin } from "kea-router";
import { localStoragePlugin } from "kea-localstorage";
import { resetContext } from "kea";
import { createPostHogClient } from "./helpers/ph";
import pkgJson from "../package.json";
import { windowValuesPlugin } from "kea-window-values";
import App from "./scenes/App/App";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

resetContext({
  plugins: [
    routerPlugin,
    localStoragePlugin({
      prefix: pkgJson.name,
    }),
    windowValuesPlugin({ window: window }),
  ],
});

createPostHogClient();

const root = createRoot(document.getElementById("root") as Element);
root.render(
  <DndProvider backend={HTML5Backend}>
    <App />
  </DndProvider>
);

reportWebVitals(sendToVercelAnalytics);
