import { TemplateData } from "shared/lib/template.types";
import {
  PersistedNavbarInteractableId,
  SelectedDocumentAndIndex,
  WebsiteType,
} from "shared/lib/types";
import {
  Component,
  COMPONENTS_THAT_CANNOT_HAVE_KIDS,
  ComponentType,
  DocumentComponent,
  NAVBAR_ICON_STATIC_CLASSNAME,
  NavbarComponent,
} from "shared/lib/component.types";
import merge from "lodash.merge";
import { isNumeric, setDeep, slugify } from "../../../../helpers/utils";
import {
  TemplateInteractableLogicProps,
  TemplateLogicProps,
} from "shared/lib/logic.types";
import {
  createEmptyNavbarItem,
  resolveAllClassNamesFromValues,
} from "../utils";
import { LinkToEnum, NavbarItemType } from "shared/lib/util.types";
import { CustomMediaShape, MediaType } from "shared/lib/media.types";

export function generateTemplateLogicKey(props: TemplateLogicProps): string {
  if (props.intakeId && props.templateId) {
    return `${props.intakeId || "new"}-${props.templateId}`;
  }
  throw new Error("Template key cannot be empty.");
}

export function generateInteractableLogicKey({
  logicProps,
  interactableId,
}: Omit<TemplateInteractableLogicProps, "type">): string {
  return `${generateTemplateLogicKey(logicProps)}-${interactableId.join("-")}`;
}

export function getNestedValueFromKey(
  tree: Record<string, any>,
  idArray: TemplateInteractableLogicProps["interactableId"]
): any {
  let value: Record<string, any> = tree;
  idArray.forEach((treeKey) => {
    if (!value?.[treeKey] && !(treeKey in value)) {
      throw new Error(
        `Cannot access value at this position in the tree: ${JSON.stringify(
          idArray
        )}`
      );
    }
    value = value[treeKey];
  });
  return value;
}

export function findNestedValueFromCondition(
  tree: TemplateData | undefined | null,
  condition: (value: any) => boolean
): TemplateInteractableLogicProps["interactableId"] {
  if (!tree) {
    return [];
  }
  const stack: [
    Record<string, any>,
    TemplateInteractableLogicProps["interactableId"]
  ][] = [[tree, ["documents"]]];

  while (stack?.length > 0) {
    const [currentObj, keyList] = stack.pop() as [
      Record<string, any>,
      TemplateInteractableLogicProps["interactableId"]
    ];
    if (condition(currentObj)) {
      return [...keyList];
    }

    for (const key of Object.keys(currentObj).filter(
      (key) => ["documents", "page", "children"].includes(key) || isNumeric(key)
    )) {
      if (Array.isArray(currentObj[key]) && currentObj[key].length > 0) {
        currentObj[key].forEach((child: any, childIndex: number) => {
          stack.push([
            child,
            key === "documents"
              ? [...keyList, childIndex]
              : [...keyList, "children", childIndex],
          ]);
        });
      } else if (
        typeof currentObj[key] === "object" &&
        currentObj[key] !== null
      ) {
        stack.push([currentObj[key], [...keyList, key]]);
      }
    }
  }
  return [];
}

export function deleteDeeplyNestedValue(
  tree: TemplateData | null | undefined,
  idArray: TemplateInteractableLogicProps["interactableId"]
): TemplateData | null {
  // Cannot delete a page
  const lastId = idArray.slice(-1)?.[0];

  if (!tree) {
    return null;
  }

  if (typeof lastId !== "number") {
    return setDeep(tree, idArray, undefined) as TemplateData;
  }

  // Handle document deletion separately
  if (idArray.length === 2) {
    const currentDocuments = [...tree.documents];
    return {
      ...tree,
      documents: [
        ...currentDocuments.slice(0, lastId),
        ...currentDocuments.slice(lastId + 1),
      ],
    } as any;
  }

  // Find current array value [documents, 0]
  const rootInteractableId = idArray.slice(0, -2); // assuming the last two elements are ["children", i]
  const currentChildren =
    getNestedValueFromKey(tree, rootInteractableId)?.children ?? [];
  const newChildren = [
    ...currentChildren.slice(0, lastId),
    ...currentChildren.slice(lastId + 1),
  ];

  return setDeep(tree, idArray.slice(0, -1), newChildren) as TemplateData;
}

export function generateNestedTree(
  value: any,
  idArray: TemplateInteractableLogicProps["interactableId"]
): any {
  let tree: any = value;
  idArray
    .slice()
    .reverse()
    .forEach((key) => {
      tree = {
        [key]: tree,
      };
    });
  return tree;
}

export function calculateCursorPositionInTemplate(
  activePopoverInteractableProps: Pick<
    TemplateInteractableLogicProps,
    "interactableId"
  >,
  activeDocumentAndDocumentIndex: SelectedDocumentAndIndex,
  type: ComponentType,
  forceSibling: boolean = false
): [TemplateInteractableLogicProps["interactableId"], number] {
  // Only some components can have children. If false the component is added as a sibling.
  if (
    [
      ComponentType.Document,
      ComponentType.Page,
      ComponentType.Navbar,
      "save",
      undefined,
      null,
    ].includes(type)
  ) {
    return [
      ["documents", activeDocumentAndDocumentIndex.index, "page"],
      activeDocumentAndDocumentIndex.document?.page?.children?.length ?? 0,
    ]; // add to end
  }

  if (
    COMPONENTS_THAT_CANNOT_HAVE_KIDS.includes(type as ComponentType) ||
    forceSibling
  ) {
    // Go up one level to parent (removes "children" and child index)
    return [
      activePopoverInteractableProps.interactableId.slice(0, -2),
      ((activePopoverInteractableProps.interactableId.at(-1) as number) ?? -1) +
        1,
    ];
  }

  // Handles other components that can have children added to them normally
  return [activePopoverInteractableProps.interactableId, 0];
}

export function addComponentToTree<T extends Component>(
  componentToAdd: T,
  website: WebsiteType,
  currentCursorPosition: [
    TemplateInteractableLogicProps["interactableId"],
    number
  ],
  persistedNavbarInteractableId: PersistedNavbarInteractableId | null
): {
  nextWebsite: WebsiteType;
  // Also return some metadata that is sometimes helpful
  rootInteractableId: TemplateInteractableLogicProps["interactableId"];
  indexToAddAt: number;
} {
  if (
    componentToAdd.type === ComponentType.Document ||
    componentToAdd.type === ComponentType.Page
  ) {
    // Adds a new document to template root
    const currentDocuments = website.data?.documents ?? [];
    // componentToAdd is always document at this point
    const newDocumentName = `Page ${currentDocuments.length + 1}`;
    const _componentToAdd = {
      ...componentToAdd,
      persistedNavbarInteractableId,
      slug: {
        slug: slugify(newDocumentName),
        name: newDocumentName,
        default: currentDocuments.length === 0,
      },
    } as DocumentComponent;
    return {
      nextWebsite: {
        ...website,
        data: {
          ...website.data,
          documents: [...currentDocuments, _componentToAdd],
        },
      },
      rootInteractableId: ["documents"],
      indexToAddAt: currentDocuments.length,
    };
  }

  // If adding a navbar, always add to root of page object
  if (componentToAdd.type === ComponentType.Navbar) {
    const currentDocumentId = currentCursorPosition[0].slice(0, 2);
    const currentDocumentIndex = Number(currentDocumentId.slice(-1)[0]);
    const currentDocuments = website.data?.documents ?? [];
    const currentDocumentsNavbar =
      currentDocuments[currentDocumentIndex].navbar;

    // When adding a navbar, prepopulate it with existing routes and Logo
    const emptyNavbarItem = createEmptyNavbarItem({ new: false });
    const spacerNavbarItem = createEmptyNavbarItem({
      values: {
        ...emptyNavbarItem.values,
        to: {
          type: LinkToEnum.Spacer,
        },
      },
      new: false,
    });
    const iconNavbarItemStyles = {
      w: 10,
      image: {
        type: MediaType.Custom,
        src: "https://kwkmnmxjpngtpycmfkgm.supabase.co/storage/v1/object/public/media/staging/new/dfab7bf8159681f47a0c78d176e567ba74538ccf.png",
        aspectRatio: 1.21,
        position: "center",
        repeat: "no-repeat",
        attachment: "local",
        size: "contain",
      } as CustomMediaShape,
    };
    const iconNavbarItem = createEmptyNavbarItem({
      values: {
        ...emptyNavbarItem.values,
        to: {
          type: LinkToEnum.Icon,
          className: resolveAllClassNamesFromValues(
            NavbarItemType.NavbarItem,
            iconNavbarItemStyles
          ),
          staticClassName: NAVBAR_ICON_STATIC_CLASSNAME,
          styles: iconNavbarItemStyles,
        },
      },
      new: false,
    });
    const logoNavbarItem = createEmptyNavbarItem({
      values: {
        ...emptyNavbarItem.values,
        to: {
          type: LinkToEnum.Page,
          label: "Acme Company",
          slugInteractableId: ["documents", 0, "slug"],
        },
      },
      styles: {
        ...emptyNavbarItem.styles,
        family: "header",
        size: "2xl",
        weight: "extrabold",
      },
      new: false,
    });
    const componentToAddPrepopulated = {
      ...componentToAdd,
      values: {
        ...componentToAdd.values,
        items: [
          iconNavbarItem,
          logoNavbarItem,
          spacerNavbarItem,
          ...currentDocuments.map(({ slug }, docIndex) => ({
            ...emptyNavbarItem,
            values: {
              ...emptyNavbarItem.values,
              to: {
                type: LinkToEnum.Page,
                label: slug.name,
                slugInteractableId: ["documents", docIndex, "slug"],
              },
            },
          })),
        ],
      },
    } as NavbarComponent;

    return {
      nextWebsite: {
        ...website,
        data: {
          ...website.data,
          documents: [
            ...currentDocuments.slice(0, currentDocumentIndex),
            {
              ...currentDocuments[currentDocumentIndex],
              navbar: currentDocumentsNavbar ?? componentToAddPrepopulated,
            },
            ...currentDocuments.slice(currentDocumentIndex + 1),
          ],
        },
      },
      rootInteractableId: currentDocumentId,
      indexToAddAt: 0,
    };
  }

  const rootComponentToAddAsChildAndIndexToAddAt = currentCursorPosition;
  const rootInteractableId = rootComponentToAddAsChildAndIndexToAddAt[0];
  const indexToAddAt = rootComponentToAddAsChildAndIndexToAddAt[1];

  // Find current array value
  const currentChildren =
    getNestedValueFromKey(website.data as TemplateData, rootInteractableId)
      ?.children ?? [];
  const newChildren = [
    ...currentChildren.slice(0, indexToAddAt),
    componentToAdd,
    ...currentChildren.slice(indexToAddAt),
  ];

  const tree = generateNestedTree(
    { children: newChildren },
    rootInteractableId
  );

  const deleteEverythingUpToRoot = deleteDeeplyNestedValue(website.data, [
    ...rootInteractableId,
    "children",
  ]);

  return {
    nextWebsite: {
      ...website,
      data: merge({}, deleteEverythingUpToRoot, tree),
    },
    rootInteractableId,
    indexToAddAt,
  };
}

export function clearAllPersistedNavbars(
  website: WebsiteType | null
): WebsiteType {
  return merge({}, website, {
    data: {
      documents: (website?.data?.documents ?? []).map((doc) => ({
        ...doc,
        persistedNavbarInteractableId: null,
        navbar: doc.navbar
          ? {
              ...doc.navbar,
              values: {
                ...doc.navbar.values,
                persist: false,
              },
            }
          : null,
      })),
    },
  });
}

export function setAllPersistedNavbars(
  website: WebsiteType | null,
  newPersistedNavbarInteractableId: PersistedNavbarInteractableId
): WebsiteType {
  return merge({}, website, {
    data: {
      documents: (website?.data?.documents ?? []).map((doc) => ({
        ...doc,
        persistedNavbarInteractableId: newPersistedNavbarInteractableId,
      })),
    },
  });
}

export function findDocumentIndexWithPersistedNavbar(
  templateData: TemplateData | null | undefined
): number {
  return (
    templateData?.documents?.findIndex(
      (doc) => !!doc.navbar?.values?.persist
    ) ?? -1
  );
}
