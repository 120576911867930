import {
  actions,
  connect,
  kea,
  key,
  listeners,
  path,
  props,
  selectors,
} from "kea";
import { templateLogic } from "../templateLogic";
import {
  generateInteractableLogicKey,
  generateNestedTree,
  getNestedValueFromKey,
} from "../templateDataUtils";
import {
  Component,
  ComponentType,
  ComponentWithValues,
} from "shared/lib/component.types";
import { templateControlLogic } from "../templateControlLogic";
import { Direction, MobilePayload, Slug } from "shared/lib/util.types";
import equal from "lodash.isequal";
import {
  createEmptyComponent,
  resolveAllClassNamesFromValues,
  resolveClassNameFromValues,
} from "../../utils";
import clsx from "clsx";
import merge from "lodash.merge";
import { TemplateInteractableLogicProps } from "shared/lib/logic.types";
import type { templateInteractableLogicType } from "./templateInteractableLogicType";
import { TemplateData } from "shared/lib/template.types";

export enum Side {
  Top = "top",
  Right = "right",
  Bottom = "bottom",
  Left = "left",
  Inner = "inner",
}

export const templateInteractableLogic = kea<templateInteractableLogicType>([
  props({} as TemplateInteractableLogicProps),
  path((key) => [
    "src",
    "components",
    "Template",
    "templateInteractableLogic",
    key,
  ]),
  key(generateInteractableLogicKey),
  connect(({ logicProps }: TemplateInteractableLogicProps) => ({
    values: [
      templateLogic(logicProps),
      ["templateData"],
      templateControlLogic(logicProps),
      ["activePopoverInteractableProps"],
    ],
    actions: [templateLogic(logicProps), ["setTemplateData"]],
  })),
  actions({
    onChange: (value: {
      slug?: Slug;
      styles?: Partial<Component["styles"]>;
      values?: Partial<ComponentWithValues["values"]>;
      mobile?: Partial<MobilePayload<Component>>;
    }) => ({ value }),
  }),
  selectors(({ props }) => ({
    props: [() => [(_, props) => props], (props) => props],
    type: [(s) => [s.component], (component) => component.type],
    component: [
      (s) => [s.templateData],
      (data: TemplateData) => {
        if (!data) {
          return null;
        }
        const component = getNestedValueFromKey(data, props.interactableId);
        // clean up component before trying to render in sidebar
        return {
          ...createEmptyComponent(component.type),
          ...component,
        };
      },
    ],
    parentComponent: [
      (s) => [s.templateData],
      (data: TemplateData) =>
        data && getNestedValueFromKey(data, props.parentInteractableId),
    ],
    parentsLayoutDirection: [
      (s) => [s.parentComponent],
      (parentComponent): Direction => {
        if (!parentComponent?.styles?.contents) {
          return "column";
        }
        const parentContentsStyle = parentComponent.styles.contents;
        return parentContentsStyle.direction;
      },
    ],
    isInteractablePopoverOpen: [
      (s) => [s.props, s.activePopoverInteractableProps],
      (thisProps, activeProps) =>
        equal(thisProps.interactableId, activeProps.interactableId),
    ],
    addableSides: [
      // Sides of the component you can add new components to when you're dragging.
      (s) => [
        s.props,
        s.component,
        s.type,
        s.parentComponent,
        s.parentsLayoutDirection,
      ],
      (
        props,
        component,
        type,
        parentComponent,
        parentsLayoutDirection
      ): Set<Side> => {
        // Document and page cannot have components added as siblings
        if (
          !parentComponent ||
          !component ||
          [ComponentType.Document, ComponentType.Page].includes(type)
        ) {
          return new Set();
        }
        // Figure out which direction parent component is
        if (
          ![ComponentType.Page, ComponentType.Box].includes(
            parentComponent.type
          )
        ) {
          return new Set();
        }

        const sides = [];
        const isRowDirection = parentsLayoutDirection === "row";

        // If current component doesn't have any children and is a layout component type, add inner as an addable side.
        if (
          [ComponentType.Page, ComponentType.Box].includes(component.type) &&
          component.children.length === 0
        ) {
          sides.push(Side.Inner);
        }

        return new Set([
          ...sides,
          ...(isRowDirection
            ? [Side.Right, Side.Left]
            : [Side.Top, Side.Bottom]),
        ]);
      },
    ],
  })),
  listeners(({ props, actions, values }) => ({
    onChange: ({ value }) => {
      if (value === undefined) {
        return;
      }

      let nextValue: any = value;
      // Calculate new classnames whenever styles or mobile styles change
      if (value.styles || value.mobile) {
        const newClassName = clsx(
          resolveAllClassNamesFromValues(
            values.type,
            merge({}, values.component.styles, value.styles ?? {}),
            merge(
              {},
              values.component.mobile,
              "mobile" in value
                ? (value.mobile as MobilePayload<Component>)
                : {}
            )
          )
        );
        nextValue = {
          ...value,
          className: newClassName,
        };
      }

      let tree = generateNestedTree(nextValue, props.interactableId);

      // If changing the slug of one route, make sure exactly one default slug is selected across all documents
      if (value.slug && "default" in value.slug) {
        // Blank out all defaults in document slugs
        const blankedDefaultTrees = Array.from(
          Array(values.templateData?.documents?.length ?? 0).keys()
        ).map((docIndex) =>
          generateNestedTree(
            {
              default: false,
            },
            ["documents", docIndex, "slug"]
          )
        );
        const defaultTreeWithSlug =
          value.slug?.default === true
            ? tree
            : generateNestedTree({ default: true }, ["documents", 0, "slug"]);
        tree = merge({}, ...blankedDefaultTrees, defaultTreeWithSlug);
      }
      actions.setTemplateData(tree);
    },
  })),
]);
