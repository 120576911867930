import {
  actions,
  afterMount,
  connect,
  kea,
  key,
  listeners,
  path,
  props,
  reducers,
  selectors,
} from "kea";
import { loaders } from "kea-loaders";
import {
  PersistedNavbarInteractableId,
  SelectedDocumentAndIndex,
  SidebarTab,
  WebsiteType,
} from "shared/lib/types";
import {
  addComponentToTree,
  calculateCursorPositionInTemplate,
  clearAllPersistedNavbars,
  deleteDeeplyNestedValue,
  findDocumentIndexWithPersistedNavbar,
  findNestedValueFromCondition,
  generateTemplateLogicKey,
  getNestedValueFromKey,
  setAllPersistedNavbars,
} from "./templateDataUtils";
import api from "../../../../helpers/api";
import { TemplateData } from "shared/lib/template.types";
import {
  Component,
  ComponentType,
  DocumentComponent,
} from "shared/lib/component.types.js";
import { createEmptyComponent } from "../utils";
import { templateControlLogic } from "./templateControlLogic";
import { setDeep, slugify } from "../../../../helpers/utils";
import { Slug } from "shared/lib/util.types";
import {
  TemplateInteractableLogicProps,
  TemplateLogicProps,
} from "shared/lib/logic.types";
import type { templateLogicType } from "./templateLogicType";
import equal from "lodash.isequal";
import merge from "lodash.merge";
import { IS_DEVELOPMENT } from "shared/lib/constants";

export const templateLogic = kea<templateLogicType>([
  path((key) => ["src", "components", "Template", "templateLogic", key]),
  props({} as TemplateLogicProps),
  key(generateTemplateLogicKey),
  connect((props: TemplateLogicProps) => ({
    values: [
      templateControlLogic(props),
      ["selectedDocument", "activePopoverInteractableProps", "leftSidebarTab"],
    ],
    actions: [
      templateControlLogic(props),
      ["setPopoverOpen", "setSelectedDocument", "setLeftSidebarTab"],
    ],
  })),
  actions({
    setRawResult: (website: WebsiteType | null, merge: boolean = false) => ({
      website,
      merge,
    }),
    setTemplateData: (templateData: Partial<TemplateData>) => ({
      templateData,
    }),
    // Fn's that mutate template data
    addComponent: (type: ComponentType) => ({
      type,
    }),
    addCustomComponent: (data: Component) => ({ data }),
    deleteComponent: (
      interactableId: TemplateInteractableLogicProps["interactableId"]
    ) => ({ interactableId }),
    duplicateComponent: (
      interactableId: TemplateInteractableLogicProps["interactableId"]
    ) => ({ interactableId }),
    moveComponent: (
      sourceInteractableId: TemplateInteractableLogicProps["interactableId"],
      positionToAddAt: [
        TemplateInteractableLogicProps["interactableId"],
        number
      ]
    ) => ({ sourceInteractableId, positionToAddAt }),
    // undo and redo
    pushToUndoStack: (website: WebsiteType | null) => ({ website }),
    setUndoStack: (stack: (WebsiteType | null)[]) => ({ stack }),
    popUndoStack: true,
    undo: true,
    setRedoStack: (stack: (WebsiteType | null)[]) => ({ stack }),
    popRedoStack: true,
    clearRedoStack: true,
    redo: true,
    // navbar related
    persistNavbar: (
      persist: boolean,
      persistedNavbarInteractableId: PersistedNavbarInteractableId
    ) => ({ persist, persistedNavbarInteractableId }),
  }),
  reducers(() => ({
    rawResult: [null as WebsiteType | null, {}],
    undoStack: [
      [] as (WebsiteType | null)[],
      {
        setUndoStack: (_, { stack }) => stack,
        popUndoStack: (prevStack) => prevStack.slice(0, prevStack.length - 1),
        clearUndoStack: () => [],
      },
    ],
    redoStack: [
      [] as (WebsiteType | null)[],
      {
        setRedoStack: (_, { stack }) => stack,
        popRedoStack: (prevStack) => prevStack.slice(0, prevStack.length - 1),
        clearRedoStack: () => [],
      },
    ],
    isSaving: [
      false,
      {
        saveTemplate: () => true,
        saveTemplateSuccess: () => false,
        saveTemplateFailure: () => false,
      },
    ],
  })),
  listeners(({ values, actions, props }) => ({
    pushToUndoStack: ({ website }) => {
      if (!website) {
        return;
      }
      if (values.undoStack.length < 1) {
        actions.setUndoStack([website]);
        actions.clearRedoStack();
      }
      if (equal(values.undoStack.slice(-1)[0], website)) {
        return;
      }
      actions.setUndoStack([...values.undoStack, website]);
      actions.clearRedoStack();
    },
    setTemplateDataSuccess: () => {
      actions.saveTemplate({});
    },
  })),
  loaders(({ props, values, actions }) => ({
    rawResult: {
      loadTemplate: async (_, breakpoint) => {
        await breakpoint(100);
        const { response } = await api.sites.get({
          ...props,
        });
        const websiteData = response?.result;
        breakpoint();
        if (response.status === "error") {
          throw new Error(response.message);
        }
        return websiteData as WebsiteType;
      },
      saveTemplate: async (_, breakpoint) => {
        // debounced save
        await breakpoint(3000);

        if (
          !values.websiteAlreadyExists ||
          !values.rawResult?.id ||
          !values.rawResult?.template_id
        ) {
          throw new Error("Cannot save website if it doesn't exist.");
        }
        const { response } = await api.sites.update({
          id: String(values.rawResult.id),
          template_id: String(values.rawResult.template_id),
          title: values.rawResult?.title ?? "",
          description: values.rawResult?.description ?? "",
          data: values.rawResult?.data ?? {},
        });
        const websiteData = response?.result;
        breakpoint();
        if (response.status === "error") {
          throw new Error(response.message);
        }
        return websiteData as WebsiteType;
      },
      setRawResult: ({ website, merge }) => {
        const nextRawResult = website ?? values.rawResult;
        return {
          ...(merge ? values.rawResult : {}),
          ...nextRawResult,
        } as WebsiteType;
      },
      save: async () => {
        return values.rawResult;
      },
      setTemplateData: ({ templateData }) => {
        const nextTemplateData = merge({}, values.rawResult, {
          data: templateData,
        });

        // TODO: bug where items in arrays aren't deleted when new template data contains null elements. Manually fix this for navbar items by cleaning out all null values
        const nextTemplateDataWithoutNullNavbarItems: WebsiteType = {
          ...nextTemplateData,
          data: {
            ...nextTemplateData.data,
            documents: nextTemplateData.data.documents.map((doc) => ({
              ...doc,
              navbar: doc.navbar
                ? {
                    ...doc.navbar,
                    values: {
                      ...doc.navbar.values,
                      items: (doc.navbar?.values?.items ?? []).filter(
                        (item) => !!item
                      ),
                    },
                  }
                : null,
            })),
          },
        };

        if (IS_DEVELOPMENT) {
          console.log(
            "Debug: Document changed",
            values.rawResult,
            nextTemplateDataWithoutNullNavbarItems
          );
        }

        return nextTemplateDataWithoutNullNavbarItems;
      },
      persistNavbar: ({ persist, persistedNavbarInteractableId }) => {
        if (persist) {
          // clear all other flags.
          const cleanWebsite = clearAllPersistedNavbars(values.rawResult);
          // set new persist flag
          const cleanWebsiteWithPersistFlag = setDeep(
            cleanWebsite,
            ["data", ...persistedNavbarInteractableId, "values", "persist"],
            true
          ) as WebsiteType;
          // set all persistedNavbarInteractableIds
          return setAllPersistedNavbars(
            cleanWebsiteWithPersistFlag,
            persistedNavbarInteractableId
          );
        } else {
          // clear all persist flags and persistedNavbarInteractableId on navbars
          return clearAllPersistedNavbars(values.rawResult);
        }
      },
      undo: () => {
        if (values.undoStack.length < 1) {
          return values.rawResult;
        }
        const lastUndoStackItem = values.undoStack.slice(-1)[0];
        if (values.rawResult) {
          actions.setRedoStack([...values.redoStack, values.rawResult]);
        }
        actions.popUndoStack();
        return lastUndoStackItem;
      },
      redo: () => {
        if (values.redoStack.length < 1) {
          return values.rawResult;
        }
        const lastRedoStackItem = values.redoStack.slice(-1)[0];
        if (values.rawResult) {
          actions.setUndoStack([...values.undoStack, values.rawResult]);
        }
        actions.popRedoStack();
        return lastRedoStackItem;
      },
      addComponent: ({ type }) => {
        if (!values.rawResult) {
          return null;
        }
        // @ts-expect-error
        const toAdd = createEmptyComponent(type);

        const { nextWebsite, indexToAddAt, rootInteractableId } =
          addComponentToTree(
            toAdd,
            values.rawResult,
            values.currentCursorPosition,
            values.persistedNavbarInteractableId
          );

        if (type === ComponentType.Document || type === ComponentType.Page) {
          actions.setSelectedDocument(
            toAdd,
            values.rawResult.data.documents.length
          );
          // Also open up left sidebar
          if (values.leftSidebarTab.tab !== SidebarTab.Pages) {
            actions.setLeftSidebarTab(SidebarTab.Pages);
          }
        } else if (type === ComponentType.Navbar) {
          actions.setPopoverOpen(true, {
            parentInteractableId: rootInteractableId,
            interactableId: [...rootInteractableId, "navbar"],
            logicProps: props,
          });
        } else {
          actions.setPopoverOpen(true, {
            parentInteractableId: rootInteractableId,
            interactableId: [...rootInteractableId, "children", indexToAddAt],
            logicProps: props,
          });
        }
        actions.pushToUndoStack(values.rawResult);
        return nextWebsite;
      },
      addCustomComponent: async ({ data }) => {
        if (!values.rawResult) {
          return null;
        }
        const toAdd = data;

        const { nextWebsite } = addComponentToTree(
          toAdd,
          values.rawResult,
          values.currentCursorPosition,
          values.persistedNavbarInteractableId
        );

        actions.pushToUndoStack(values.rawResult);
        return nextWebsite;
      },
      duplicateComponent: async ({ interactableId }) => {
        if (!values.rawResult) {
          return null;
        }
        const notPageInteractable =
          interactableId.slice(-1)?.[0] === ComponentType.Page
            ? interactableId.slice(0, -1)
            : interactableId;
        let toAdd = getNestedValueFromKey(
          values.rawResult.data,
          notPageInteractable
        );
        if (toAdd.type === ComponentType.Document) {
          const copyName = toAdd.slug.name ? `${toAdd.slug.name} (copy)` : "";
          toAdd = {
            ...toAdd,
            slug: {
              slug: slugify(copyName),
              name: copyName,
            },
          };
        }
        const { nextWebsite, indexToAddAt, rootInteractableId } =
          addComponentToTree(
            toAdd,
            values.rawResult,
            values.currentCursorPositionSibling,
            values.persistedNavbarInteractableId
          );

        if (
          toAdd.type === ComponentType.Document ||
          toAdd.type === ComponentType.Page
        ) {
          values.leftSidebarTab.tab !== SidebarTab.Pages &&
            actions.setLeftSidebarTab(SidebarTab.Pages);
          actions.setSelectedDocument(
            toAdd,
            values.rawResult.data.documents.length
          );
        } else {
          actions.setPopoverOpen(true, {
            parentInteractableId: rootInteractableId,
            interactableId: [...rootInteractableId, "children", indexToAddAt],
            logicProps: props,
          });
        }

        actions.pushToUndoStack(values.rawResult);
        return nextWebsite;
      },
      deleteComponent: ({ interactableId }) => {
        // Cannot delete a page
        const newTree = deleteDeeplyNestedValue(
          values.rawResult?.data,
          interactableId
        );

        if (!newTree) {
          return null;
        }

        actions.setPopoverOpen(false);

        const nextWebsite = {
          ...values.rawResult,
          data: newTree,
        } as any;
        actions.pushToUndoStack(values.rawResult);
        return nextWebsite;
      },
      moveComponent: async ({ sourceInteractableId, positionToAddAt }) => {
        if (!values.rawResult) {
          return null;
        }
        //  mark the old component for deletion, add component, then delete old component.
        const toAdd = getNestedValueFromKey(
          values.rawResult.data,
          sourceInteractableId
        );

        const copyData = setDeep(values.rawResult.data, sourceInteractableId, {
          ...toAdd,
          delete: true,
        }) as TemplateData;

        console.log("COPY DATA", copyData);

        const { nextWebsite, indexToAddAt, rootInteractableId } =
          addComponentToTree(
            { ...toAdd, delete: false },
            { ...values.rawResult, data: copyData },
            positionToAddAt,
            values.persistedNavbarInteractableId
          );

        const interactableIdToDelete = findNestedValueFromCondition(
          nextWebsite?.data,
          (value) => value.type === toAdd.type && !!value.delete
        );
        const nextTree = (deleteDeeplyNestedValue(
          nextWebsite?.data,
          interactableIdToDelete
        ) || nextWebsite?.data) as TemplateData;

        actions.setPopoverOpen(true, {
          parentInteractableId: rootInteractableId,
          interactableId: [...rootInteractableId, "children", indexToAddAt],
          logicProps: props,
        });

        const finalNextWebsite = { ...nextWebsite, data: nextTree };
        actions.pushToUndoStack(values.rawResult);

        console.log("COPY DATA NEXT", finalNextWebsite);
        return finalNextWebsite;
      },
    },
  })),
  selectors(() => ({
    websiteAlreadyExists: [
      () => [(_, props) => props],
      (props: TemplateLogicProps): boolean =>
        !!props.intakeId && props.intakeId !== "new",
    ],
    emptyTemplateData: [
      (s) => [s.rawResult],
      (rawResult) => rawResult?.data ?? null,
    ],
    templateData: [
      (s) => [s.rawResult],
      (rawResult) => rawResult?.data ?? null,
    ],
    templateDataLoading: [
      (s) => [s.rawResultLoading],
      (rawResultLoading) => rawResultLoading,
    ],
    templateStyles: [
      (s) => [s.templateData],
      (templateData) => ({ ...templateData?.styles } ?? null),
    ],
    routes: [
      (s) => [s.templateData],
      (
        templateData
      ): {
        slugInteractableId: (string | number)[];
        slug: Slug;
      }[] => {
        return (
          templateData?.documents?.map(
            (doc: DocumentComponent, index: number) => ({
              slugInteractableId: ["documents", index, "slug"],
              slug: doc.slug,
            })
          ) ?? []
        );
      },
    ],
    persistedNavbarInteractableId: [
      (s) => [s.templateData],
      (templateData): PersistedNavbarInteractableId | null => {
        const docIndexWithPersistedNavbar =
          findDocumentIndexWithPersistedNavbar(templateData);
        return docIndexWithPersistedNavbar === -1
          ? null
          : ["documents", docIndexWithPersistedNavbar, "navbar"];
      },
    ],
    notFound: [
      (s) => [s.rawResultLoading, s.rawResult],
      (loading, data) => !loading && !data,
    ],
    getComponent: [
      (s) => [s.templateData],
      (templateData) =>
        (interactableId: TemplateInteractableLogicProps["interactableId"]) => {
          return (
            templateData && getNestedValueFromKey(templateData, interactableId)
          );
        },
    ],
    activeDocumentAndDocumentIndex: [
      (s) => [
        s.templateData,
        s.selectedDocument,
        s.activePopoverInteractableProps,
        s.persistedNavbarInteractableId,
      ],
      (
        templateData,
        selectedDocument,
        activePopoverInteractableProps,
        persistedNavbarInteractableId
      ): SelectedDocumentAndIndex => {
        function injectPersistNavbar(
          doc: DocumentComponent | null
        ): DocumentComponent | null {
          if (!persistedNavbarInteractableId || !doc) {
            return doc;
          }
          return {
            ...doc,
            persistedNavbarInteractableId: persistedNavbarInteractableId,
          };
        }

        // Attempt to determine active document from popover id.
        if (
          activePopoverInteractableProps.interactableId[0] ===
            ComponentType.Document &&
          typeof activePopoverInteractableProps.interactableId?.[1] ===
            "number" &&
          templateData?.documents?.[
            activePopoverInteractableProps.interactableId[1]
          ]
        ) {
          const index = activePopoverInteractableProps.interactableId[1];
          return {
            document: injectPersistNavbar(templateData.documents[index]),
            index,
          };
        }

        if (!templateData) {
          return {
            ...selectedDocument,
            document: injectPersistNavbar(selectedDocument.document),
          };
        }

        return {
          ...selectedDocument,
          document: injectPersistNavbar(
            templateData.documents[selectedDocument.index]
          ),
        };
      },
    ],
    currentCursorPosition: [
      (s) => [
        s.activePopoverInteractableProps,
        s.activeDocumentAndDocumentIndex,
        s.getComponent,
      ],
      (
        activePopoverInteractableProps,
        activeDocumentAndDocumentIndex,
        getComponent
      ): [TemplateInteractableLogicProps["interactableId"], number] =>
        calculateCursorPositionInTemplate(
          activePopoverInteractableProps,
          activeDocumentAndDocumentIndex,
          getComponent(activePopoverInteractableProps.interactableId).type
        ),
    ],
    currentCursorPositionSibling: [
      (s) => [
        s.activePopoverInteractableProps,
        s.activeDocumentAndDocumentIndex,
        s.getComponent,
      ],
      (
        activePopoverInteractableProps,
        activeDocumentAndDocumentIndex,
        getComponent
      ): [TemplateInteractableLogicProps["interactableId"], number] =>
        calculateCursorPositionInTemplate(
          activePopoverInteractableProps,
          activeDocumentAndDocumentIndex,
          getComponent(activePopoverInteractableProps.interactableId).type,
          true
        ),
    ],
    canUndo: [(s) => [s.undoStack], (stack) => stack.length > 0],
    canRedo: [(s) => [s.redoStack], (stack) => stack.length > 0],
  })),
  afterMount(({ actions }) => {
    // If domain is passed in but template id js not defined, we have to fetch the template id from the server
    actions.loadTemplate({});
  }),
]);
