import clsx from "clsx";
import React from "react";

export interface SpinnerProps {
  /** Spinner size. Small means 1rem of width */
  size?: "small" | "medium" | "large" | "xl" | "2xl";
  /** A primary spinner is purple (most suitable for white backgrounds), while an inverse one is white (for colorful backgrounds.) */
  variant?: "primary" | "inverse";
  /** Whether the trace of the spinner should be hidden. */
  traceless?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

/** Smoothly animated spinner for loading states. It does not indicate progress, only that something's happening. */
export function Spinner({
  size = "medium",
  variant = "primary",
  traceless = false,
  style,
  className,
}: SpinnerProps): JSX.Element {
  return (
    <svg
      className={clsx(
        "block shrink-0 animate-spin",
        {
          "text-stone-400": variant === "primary",
          "text-white": variant === "inverse",
        },
        {
          "h-4 w-4": size === "small",
          "h-8 w-8": size === "medium",
          "h-16 w-16": size === "large",
          "h-24 w-24": size === "xl",
          "h-32 w-32": size === "2xl",
        },
        className
      )}
      style={{
        transition: "color 100ms ease",
        ...style,
      }}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!traceless && (
        <circle
          className="block origin-center fill-transparent stroke-current opacity-25"
          cx="24"
          cy="24"
          r="20"
          style={{ strokeWidth: 8 }}
        />
      )}
      <circle
        className="block origin-center animate-[writhe_1.2s_infinite_ease_both] fill-transparent stroke-current"
        cx="24"
        cy="24"
        r="20"
        style={{
          strokeWidth: 8,
          strokeLinecap: "round",
          strokeDashoffset: "-30",
          strokeDasharray: "60",
        }}
      />
    </svg>
  );
}
