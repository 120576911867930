import { templateInteractableLogic } from "../../TemplateInteractable/templateInteractableLogic";
import { useActions, useValues } from "kea";
import { templateControlLogic } from "../../templateControlLogic";
import { Component, ComponentType } from "shared/lib/component.types";
import { CgClose, CgDuplicate, CgTrash } from "react-icons/cg";
import { RiSaveLine } from "react-icons/ri";
import { templateLogic } from "../../templateLogic";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import { Slug } from "shared/lib/util.types";
import { TemplateData } from "shared/lib/template.types";
import {
  TemplateInteractableLogicProps,
  TemplateLogicProps,
} from "shared/lib/logic.types";
import {
  LeftSidebarInnerTab,
  PersistedNavbarInteractableId,
  PopoverContentsTab,
  SidebarTab,
} from "shared/lib/types";
import { libraryLogic } from "../../../library/libraryLogic";
import { Spinner } from "../../../../../../components/Spinner";
import {
  generateNestedTree,
  getNestedValueFromKey,
} from "../../templateDataUtils";
import { createEmptyComponent } from "../../../utils";

export interface NonPopoverContentProps {
  id?: string;
  logicProps: TemplateLogicProps;
}

export interface PopoverContentProps {
  props: TemplateInteractableLogicProps;
  tab?: PopoverContentsTab;
  // a string of keys that define the root of the styles payload. Default is [] which indicates the root of the component type.
  rootStylesTreeNode?: string[];
}

export function usePopoverContent<T = Component>(
  props: PopoverContentProps["props"],
  rootStylesTreeNode: string[] = []
): {
  component: T;
  _component: T;
  type: ComponentType;
  onChange: (a: any) => void;
  setPopoverOpen: (isPopoverOpen: boolean) => void;
  setLeftSidebarTab: (tab: SidebarTab, innerTab?: LeftSidebarInnerTab) => void;
  persistNavbar: (
    persist: boolean,
    persistedNavbarInteractableId: PersistedNavbarInteractableId
  ) => void;
  routes: {
    slugInteractableId: (string | number)[];
    slug: Slug;
  }[];
  templateData: TemplateData | null;
  templateStyles: Partial<TemplateData["styles"]> | null;
} {
  // specific interactable specific
  const { component: _component, type } = useValues(
    templateInteractableLogic(props)
  );
  const { onChange: _onChange } = useActions(templateInteractableLogic(props));

  const component = getNestedValueFromKey(_component, rootStylesTreeNode);
  function onChange(a: any): void {
    const nextNestedValue = generateNestedTree(a, rootStylesTreeNode);
    _onChange(nextNestedValue);
  }

  // Website wide variables
  const { setPopoverOpen, setLeftSidebarTab } = useActions(
    templateControlLogic(props.logicProps)
  );
  const { routes, templateData, templateStyles } = useValues(
    templateLogic(props.logicProps)
  );
  const { persistNavbar } = useActions(templateLogic(props.logicProps));
  return {
    component,
    _component,
    type,
    onChange,
    setPopoverOpen,
    setLeftSidebarTab,
    routes,
    templateData,
    templateStyles,
    persistNavbar,
  };
}

export function useComponentTypeKeys({
  type,
}: {
  type: ComponentType;
}): Record<string, boolean> {
  return Object.fromEntries(
    // @ts-expect-error
    Object.keys(createEmptyComponent(type).styles).map((key) => [key, true])
  );
}

export function PopoverHeader({
  title,
  props,
  className,
}: {
  title: string;
  flush?: boolean /* no padding */;
  className?: string;
} & PopoverContentProps): JSX.Element {
  const { type } = useValues(templateInteractableLogic(props));
  const logic = templateLogic(props.logicProps);
  const controlLogic = templateControlLogic(props.logicProps);
  const blocksLogic = libraryLogic(props.logicProps);
  const { deleteComponent, duplicateComponent } = useActions(logic);
  const { saveComponentToLibrary } = useActions(blocksLogic);
  const { blocksLoading } = useValues(blocksLogic);
  const { setPopoverOpen } = useActions(controlLogic);

  return (
    <div
      id="popover-header"
      className={clsx(
        "flex flex-row gap-1 justify-between items-center text-white",
        className
      )}
    >
      <div className="w-full flex flex-row justify-between border-b-2 border-stone-750">
        <div className="p-3 text-sm font-semibold">{title}</div>
        <div className="flex-row flex divide-x-2 divide-stone-750">
          {type !== ComponentType.Page && (
            <div className="flex flex-row items-center gap-1 pr-2">
              {type !== ComponentType.Document &&
                props.logicProps.intakeId !== "new" && (
                  <HoverableButton
                    onClick={() => {
                      saveComponentToLibrary(props.interactableId);
                    }}
                    className="cursor-pointer"
                    loading={blocksLoading ? <Spinner size="small" /> : null}
                    title={"Save component to library"}
                  >
                    <RiSaveLine className="text-base" />
                  </HoverableButton>
                )}
              {type !== ComponentType.Navbar && (
                <HoverableButton
                  onClick={() => {
                    duplicateComponent(props.interactableId);
                  }}
                  className="cursor-pointer"
                  title="Duplicate"
                >
                  <CgDuplicate className="text-base" />
                </HoverableButton>
              )}
              <HoverableButton
                onClick={() => {
                  deleteComponent(props.interactableId);
                }}
                className="cursor-pointer"
                title="Delete"
              >
                <CgTrash className="text-base" />
              </HoverableButton>
            </div>
          )}
          <div className={clsx("flex flex-row items-center gap-1 px-2")}>
            <HoverableButton
              onClick={() => {
                setPopoverOpen(false);
              }}
              className="cursor-pointer"
              title="Close sidebar"
            >
              <CgClose className="text-base" />
            </HoverableButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export function HoverableButton({
  children,
  className,
  active,
  disabled,
  loading,
  onClick,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  children: ReactNode;
  active?: boolean;
  disabled?: boolean;
  loading?: ReactNode; // loading component
}): JSX.Element {
  const isDisabled = disabled || !!loading;

  return (
    <div
      className={clsx(
        "hover:text-white text-gray-400 transition-colors p-1 flex justify-center items-center rounded-md",
        active && "bg-stone-700 text-white",
        isDisabled ? "cursor-not-allowed opacity-70" : "cursor-pointer",
        className
      )}
      onClick={(e) => {
        !isDisabled && onClick?.(e);
      }}
      aria-disabled={isDisabled}
      {...props}
    >
      <>{loading || children}</>
    </div>
  );
}

export function InnerTabs({
  hasValuesTab,
  hasStylesTab = true,
  hasMobileTab = false,
  tab,
  setTab,
  className,
  items = [
    {
      tab: PopoverContentsTab.Values,
      label: "Values",
      hidden: !hasValuesTab,
    },
    {
      tab: PopoverContentsTab.Styles,
      label: "Styles",
      hidden: !hasStylesTab,
    },
    {
      tab: PopoverContentsTab.Mobile,
      label: "Mobile",
      hidden: !hasMobileTab,
    },
  ],
}: {
  hasValuesTab: boolean;
  hasStylesTab?: boolean;
  hasMobileTab?: boolean;
  tab: string;
  setTab: (tab: string) => void;
  className?: string;
  items?: { tab: string; label: string; hidden?: boolean }[];
}): JSX.Element {
  return (
    <div
      className={clsx(
        "flex flex-row justify-start gap-1 border-stone-750 text-sm p-2",
        className
      )}
    >
      {items
        .filter(({ hidden }) => !hidden)
        .map((item) => (
          <div
            key={item.tab}
            className={clsx(
              "py-1 px-2 cursor-pointer text-gray-400 rounded transition-colors",
              tab === item.tab
                ? "bg-stone-700 text-white"
                : "bg-transparent hover:text-white"
            )}
            onClick={() => setTab(item.tab)}
          >
            {item.label}
          </div>
        ))}
    </div>
  );
}
