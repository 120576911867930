import {
  kea,
  key,
  props,
  path,
  defaults,
  actions,
  connect,
  afterMount,
  selectors,
} from "kea";
import {
  TemplateInteractableLogicProps,
  TemplateLogicProps,
} from "shared/lib/logic.types";
import {
  generateTemplateLogicKey,
  getNestedValueFromKey,
} from "../Template/templateDataUtils";
import type { libraryLogicType } from "./libraryLogicType";
import { loaders } from "kea-loaders";
import { ComponentRow, LibraryTab } from "shared/lib/types";
import api from "../../../../helpers/api";
import { templateLogic } from "../Template/templateLogic";
import { userLogic } from "../../../../helpers/userLogic";
import { DEFAULT_BLOCKS, DefaultBlockType } from "./defaultComponents";

export const LIBRARY_TABS = [
  LibraryTab.Layout,
  LibraryTab.Blocks,
  LibraryTab.Basic,
  LibraryTab.Custom,
];

export type BlockType = ComponentRow | DefaultBlockType;

export const libraryLogic = kea<libraryLogicType>([
  path(["src", "scenes", "Intake", "templates", "library", "libraryLogic"]),
  props({} as TemplateLogicProps),
  key(generateTemplateLogicKey),
  defaults({
    blocks: [] as BlockType[],
  }),
  connect((props: TemplateLogicProps) => ({
    values: [templateLogic(props), ["rawResult"], userLogic, ["user"]],
  })),
  actions(() => ({
    saveComponentToLibrary: (
      interactableId: TemplateInteractableLogicProps["interactableId"]
    ) => ({ interactableId }),
  })),
  loaders(({ props, values }) => ({
    blocks: {
      loadBlocks: async (_, breakpoint) => {
        if (!props.intakeId || props.intakeId === "new") {
          return DEFAULT_BLOCKS;
        }
        await breakpoint(100);
        const { response } = await api.components.list(Number(props.intakeId));
        const blocks = response?.result;
        breakpoint();
        if (response.status === "error") {
          throw new Error(response.message);
        }
        return [...DEFAULT_BLOCKS, ...(blocks ?? [])] as BlockType[];
      },
      saveComponentToLibrary: async ({ interactableId }, breakpoint) => {
        if (
          !values.rawResult ||
          !props.intakeId ||
          props.intakeId === "new" ||
          !values.user
        ) {
          return [];
        }

        await breakpoint(100);
        const toAdd = getNestedValueFromKey(
          values.rawResult.data,
          interactableId
        );

        const { response } = await api.components.create({
          website_id: props.intakeId,
          tags: [LibraryTab.Custom],
          data: toAdd,
        });
        const componentData = response?.result;
        breakpoint();

        if (response.status === "error") {
          throw new Error(response.message);
        }
        return [...values.blocks, componentData] as BlockType[];
      },
    },
  })),
  selectors(() => ({
    tagsToBlocks: [
      (s) => [s.blocks],
      (blocks) => {
        const tagsToBlocks: Record<LibraryTab, BlockType[]> =
          Object.fromEntries(
            LIBRARY_TABS.map((tab) => [tab, []])
          ) as unknown as Record<LibraryTab, BlockType[]>;
        blocks.forEach((block) => {
          (block.tags ?? []).forEach((tag) => {
            // @ts-expect-error
            tagsToBlocks[tag] = [...(tagsToBlocks[tag] ?? []), block];
          });
        });
        return tagsToBlocks;
      },
    ],
  })),
  afterMount(({ actions }) => {
    actions.loadBlocks({});
  }),
]);
