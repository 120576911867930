import { actions, kea, reducers, path } from "kea";
import type { upsellModalLogicType } from "./upsellModalLogicType";

export const upsellModalLogic = kea<upsellModalLogicType>([
  path(["src", "components", "UpsellModal", "upsellModalLogic"]),
  actions(() => ({
    openModal: true,
    closeModal: true,
  })),
  reducers(() => ({
    open: [
      false,
      {
        openModal: () => true,
        closeModal: () => false,
      },
    ],
  })),
]);
