import { Slide, ToastContainer as RTToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ToastContainer.css";

export function ToastContainer(): JSX.Element {
  return (
    <RTToastContainer
      autoClose={3000}
      transition={Slide}
      position="top-center"
      bodyClassName="text-sm"
    />
  );
}
