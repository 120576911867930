import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import clsx from "clsx";
import { CgClose } from "react-icons/cg";
import { HoverableButton } from "../scenes/Intake/templates/Template/Overlays/PopoverContent/popover.shared";

interface ModalProps {
  open: boolean;
  closeModal: () => void;
  children?: React.ReactNode;
  title?: React.ReactNode;
  className?: string;
  variant?: "light" | "dark";
  fullScreen?: boolean;
  flush?: boolean;
  hideClose?: boolean;
}

export function Modal({
  open,
  closeModal,
  children,
  title,
  className,
  variant = "light",
  fullScreen = false,
  flush = false,
  hideClose = false,
}: ModalProps): JSX.Element {
  const isLightTheme = variant === "light";

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-stone-950 bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-90"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-90"
            >
              <Dialog.Panel
                className={clsx(
                  "relative w-full transform overflow-hidden rounded-lg text-left align-middle shadow-xl transition-all",
                  fullScreen ? "max-w-6xl h-[calc(100vh-3rem)]" : "max-w-lg",
                  {
                    "bg-white text-black": isLightTheme,
                    "bg-stone-900 text-white": !isLightTheme,
                  },
                  !flush && "p-6",
                  className
                )}
              >
                {!hideClose && (
                  <HoverableButton
                    onClick={() => {
                      closeModal();
                    }}
                    className="cursor-pointer absolute top-2 right-2"
                    title="Close modal"
                  >
                    <CgClose className="text-base transition-colors" />
                  </HoverableButton>
                )}
                {title && (
                  <Dialog.Title
                    className={clsx(
                      "my-3 text-center text-xl font-bold leading-6"
                    )}
                  >
                    {title}
                  </Dialog.Title>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
